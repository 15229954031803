import get from 'lodash/get';
import React, { FunctionComponent, useState } from 'react';
import { FieldProps } from 'formik';
import { ImageInput } from 'components';
import FieldContainer from './FieldContainer';

interface IProps {
    id?: string;
    className?: string;
    label?: string;
    disabled?: boolean;
    hasId: (id: string[]) => void;
    secondaryLabel?: string;
    multipleImages?: boolean;
    multipleLanguages?: boolean;
    rank?: number;
    name?: string;
    initialImages?: any[];
    values?: any[];
    showRequiredAsterisk? : boolean
    fontWeight?: string;
}

const ImageInputField: FunctionComponent<FieldProps & IProps> = (props) => {
    const {
        id,
        field,
        form,
        disabled,
        hasId,
        secondaryLabel,
        multipleImages,
        initialImages,
        multipleLanguages,
        rank,
    } = props;
    const [imageList, setImageList] = useState<any[]>([]);
    const handleChange = (value: any) => {
        if (value) {
            if (multipleLanguages) {
                for (let index = 0; index < form.values.languages.length; index++) {
                    const fieldName = `content${form.values.languages[index].toUpperCase()}[${rank}].content`;
                    form.setFieldValue(fieldName, JSON.stringify(value));
                    form.setFieldTouched(fieldName, true);
                }
            } else {
                form.setFieldValue(field.name, value);
                form.setFieldTouched(field.name, true);
            }

            setImageList([...value]);
        }
    };

    return (
        <FieldContainer {...props}>
            <ImageInput
                {...field}
                id={id}
                imageList={imageList}
                secondaryLabel={secondaryLabel}
                onChange={handleChange}
                hasError={!!get(form.errors, field.name) && !!get(form.touched, field.name)}
                disabled={disabled}
                hasId={hasId}
                multipleImages={multipleImages}
                initialImages={initialImages}
            />
        </FieldContainer>
    );
};

export default ImageInputField;
