import React, {
    CSSProperties,
    FC,
    forwardRef,
    Ref,
    useCallback,
    useEffect,
    useMemo,
    useState,
  } from "react";
import styled from "styled-components";

  export interface MultipleToggleProps {
    className?: Partial<
       Record<"selectedClassName" | "wrapperClassName", string>
    >;
    height?: number;
    width?: number;
    initialValue?: string;
    isWrap?: boolean;
    name?: string;
    onChange?: (value: String) => void;
    ref?: Ref<HTMLSelectElement>;
    style?: Partial<Record<"selectedStyle" | "wrapperStyle", CSSProperties>>;
    values: string[];
  }
  
  const MultipleToggle: FC<MultipleToggleProps> = forwardRef<HTMLSelectElement, Omit<MultipleToggleProps, "ref">>(
    (
      {
        className: { selectedClassName, wrapperClassName } = {
          selectedClassName: undefined,
          wrapperClassName: undefined,
        },
        height = 20,
        initialValue,
        isWrap,
        name,
        onChange,
        style: { selectedStyle, wrapperStyle } = {
          selectedStyle: undefined,
          wrapperStyle: undefined,
        },
        values,
        width = 40,
      },
      ref
    ) => {
      const [currentIndex, setCurrentIndex] = useState(() => {
        if (typeof initialValue === undefined) {
          return 0;
        }
  
        return values.findIndex((value) => initialValue === value) || 0;
      });

      const currentValue = useMemo(() => {
        const value = values[currentIndex];
  
        return value;
      }, [currentIndex, values]);

      const options = useMemo(
        () =>
          values.map((value) => <option value={value} />),
        [values]
      );

      const handleChange = useCallback(() => {}, []);
      const [enabledTouch, setEnabledTouch] = useState(false);
      const [wrap, setWrap] = useState(false);
      const [enabledOnChange, setEnabledOnChange] = useState(false);

      const callback = useCallback(() => {
        setEnabledOnChange(true);
  
        setCurrentIndex((prevIndex) => {
          if (isWrap) {
            return wrap ? prevIndex - 1 : prevIndex + 1;
          }
  
          const nextIndex = prevIndex + 1;
  
          return nextIndex === values.length ? 0 : nextIndex;
        });
      }, [isWrap, values.length, wrap]);

      const handleClick = useCallback(() => {
        if (enabledTouch) {
          return;
        }
  
        callback();
      }, [callback, enabledTouch]);
      
      const length = useMemo(() => values.length, [values.length]);
  
      useEffect(() => {
        if (currentIndex !== 0 && currentIndex !== values.length - 1) {
          return;
        }
  
        setWrap(currentIndex === values.length - 1);
      }, [currentIndex, values.length]);
  
      useEffect(() => {
        const index = values.findIndex((value) => initialValue === value);
  
        if (index < 0) {
          return;
        }
  
        setCurrentIndex(index);
      }, [initialValue, values]);
  
      useEffect(() => {
        if (!enabledOnChange || !onChange) {
          return;
        }
  
        const currentValue = values[currentIndex];
  
        onChange(currentValue);
      }, [currentIndex, enabledOnChange, onChange, values]);
  
      return (
        <>
          <Wrapper
            className={wrapperClassName}
            height={height}
            onClick={handleClick}
            style={wrapperStyle}
            width={width}
          >
            <Inner>
              <Selected
                className={selectedClassName}
                height={height}
                index={currentIndex}
                length={length}
                style={selectedStyle}
                width={width}
              >
              </Selected>
            </Inner>
          </Wrapper>
          <Select
            name={name}
            onChange={handleChange}
            ref={ref}
            value={currentValue}
          >
            {options}
          </Select>
        </>
      );
    }
  );

  export type WrapperProps = {
    height: number;
    width: number;
  };

  export type SelectedProps = {
    height: number;
    index: number;
    length: number;
    width: number;
  };
  
  export const Wrapper = styled.div<WrapperProps>`
    border: 1px solid #ccc;
    border-radius: ${({ height }) => `${height}px`};
    box-sizing: content-box;
    cursor: pointer;
    height: ${({ height }) => `${height}px`};
    padding: 4px;
    width: ${({ width }) => `${width}px`};
  `;
  
  export const Inner = styled.div`
    height: 100%;
    position: relative;
    width: 100%;
  `;
  
  export const Selected = styled.div<SelectedProps>`
    background: #aaa;
    border-radius: 50%;
    height: ${({ height }) => `${height}px`};
    left: ${({ height, index, length }) =>
      !index
        ? 0
        : `calc(((100% - ${height}px) / ${length - 1}) * ${index})`};
    position: absolute;
    transition: 100ms;
    top: 0;
    width: ${({ height }) => `${height}px`};
  `;
  
  export const Select = styled.select`
    display: none;
  `;
  
  export default MultipleToggle;