import React, { useState } from 'react';
import styled from 'styled-components';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/fr';

import '../../resources/css/style.css';
import '../../resources/css/editorComponentParagraph.css';
import { FlagFr, FlagUK } from 'components/Icons';
import I18n from 'services/i18n';

interface IProps {
    id?: string;
    value: string;
    onChange: (value: any) => void;
    hasError?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    optionsToolbar?: OptionToolbar[];
    minHeight?: number;
    lang?: string;
}

export enum OptionToolbar {
    BOLD = 'bold',
    ITALIC = 'italic',
    UNDERLINE = 'underline',
    BULLET_LIST = 'bulletedList',
    NUMBERED_LIST = 'numberedList',
    TODO_LIST = 'todoList',
    MEDIA_EMBED = 'MediaEmbed',
    UNDO = 'undo',
    REDO = 'redo',
    SEPARATOR = '|',
    LINK = 'link',
    UPLOAD_IMAGE = 'uploadImage',
    HEADING = 'heading',
}

function FullRichTextInput({ onChange, value, className, hasError, optionsToolbar, minHeight = 400, lang, id }: IProps) {
    const defaultConfig = getToolbarConfiguration(optionsToolbar);

    const [focus, setFocus] = useState(false);
    const onFocus = () => {
        setFocus(true);
    };
    const onBlur = () => {
        setFocus(false);
    };

    /**
     * Attach an handler to the 'paste' event
     * @param editor instance of ckeditor5
     */
    const listenToPasteEvent = (editor) => {
        editor.editing.view.document.on('paste', () => { onPasteContentToEditor(editor) });
    }

    /**
     * Function to be executed once the user pastes text to the rich editor.
     * It will remove all the styles appended to the data being pasted.
     *
     * Example, text color will be removed.
     *  Without the following function, pasting text with a specific color would result in the following:
     *   <p><span style="color:rgb(255,86,48);">text with color</span></p>
     *
     *  When apply the function, it will result in the text with no styles, like:
     *   <p>text with no color</p>
     */
    const onPasteContentToEditor = (editor) => {
        // use built-in 'Clipboard' plugin of the editor to listen to the inputTransformation event to have access to
        // the data being pasted after being processed by the editor and be in the proper internal format
        editor.plugins.get('Clipboard').on('inputTransformation', (event, data) => {

            removeContentStyles(data.content);
        })
    }

    /**
     * Responsible for removing the the styles of the input node and it's children.
     *
     * @param node
     */
    const removeContentStyles = (node) => {
        const hasChildren = node._textData === undefined;

        // if node has no child, means it was the last node of the current branch
        if(!hasChildren) {
            return;
        }

        if (node._styles) {
            // clear function will remove any appended styles to the node
            node._styles.clear();
        }

        // get all of the node children
        for (const element of node.getChildren()) {
            // remove any style for the each node child
            removeContentStyles(element);
        }
    }

    return (
        <WrapperRoot id={id} className={className} hasError={hasError} focus={focus}>
            {!!lang && <FlagWrapper>{lang.toLowerCase() === 'fr' ? <FlagFr /> : <FlagUK />}</FlagWrapper>}
            <CKEditor
                editor={DecoupledEditor}
                data={value}
                onBlur={onBlur}
                onFocus={onFocus}
                // @ts-ignore
                config={defaultConfig}
                onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // Insert the toolbar before the editable area.
                    // @ts-ignore
                    editor.ui
                        .getEditableElement()
                        .parentElement.insertBefore(
                          editor.ui.view.toolbar.element as Node, 
                          editor.ui.getEditableElement() as HTMLElement
                        );

                    //Setting min-height for editable part of editor
                    editor.editing.view.change((writer) => {
                        writer.setStyle(
                          'min-height', 
                          `${minHeight}px`, 
                          // @ts-ignore
                          editor.editing.view.document.getRoot()
                        );
                    });

                    listenToPasteEvent(editor);
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    onChange(data);
                }}
            />
        </WrapperRoot>
    );
}

export default FullRichTextInput;
interface InputEditorProps {
    readonly hasError?: boolean;
    readonly focus: boolean;
}

const FlagWrapper = styled.div`
    width: inherit;
    display: flex;
    flex-direction: row-reverse;
    border: 0 solid;
    border-radius: 2px;
    height: 15px;
    width: 28px;
    overflow: hidden;
    position: absolute;
    top: 13px;
    right: 7px;
`;

const WrapperRoot = styled.div<InputEditorProps>`
    width: 100%;
    position: relative;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    border: 1px solid
        ${({ hasError, focus, theme }) => {
            if (focus) return theme.color.input.borderColorFocus;
            return hasError && !focus ? theme.color.input.borderColorError : theme.color.input.borderColor;
        }};
    background-color: ${({ hasError, focus, theme }) =>
        hasError && !focus ? theme.color.input.backgroundColorError : theme.color.input.backgroundColor};
    color: ${({ hasError, theme, focus }) =>
        hasError && !focus ? theme.color.input.textColorError : theme.color.input.textColor};
    font-size: ${({ theme }) => theme.typography.fontSizeL}px;
    text-align: left;
    transition: color 300ms, background-color 300ms, border 300ms;
    &:disabled {
        cursor: not-allowed;
        opacity: ${({ theme }) => theme.opacity.disabled};
    }
    &::placeholder {
        color: ${({ theme }) => theme.color.input.placeholderColor};
    }
`;

function getToolbarConfiguration(optionsToolbar?: OptionToolbar[]) {
    let defaultConfig = {
        language: I18n.language,
        mediaEmbed: {
            previewsInData: true,
        },
        toolbar: {
            items: [
                'heading',
                'fontColor',
                '|',
                'bold',
                'italic',
                'strikethrough',
                'underline',
                'subscript',
                'superscript',
                '|',
                'alignment',
                'outdent',
                'indent',
                '|',
                'bulletedList',
                'numberedList',
                'todoList',
                '|',
                'insertTable',
                'link',
                'MediaEmbed',
                '|',
                'undo',
                'redo',
            ],
            shouldNotGroupWhenFull: true,
        },
        heading: {
            options: [
                {
                    model: 'headingTitle',
                    view: {
                        name: 'p',
                        classes: 'title',
                    },
                    title: I18n.t('component:fullRichTextInput.title1'),
                    class: 'ck-heading_heading_title',
                    converterPriority: 'high',
                },
                {
                    model: 'headingSubtitle',
                    view: {
                        name: 'p',
                        classes: 'subtitle',
                    },
                    title: I18n.t('component:fullRichTextInput.title2'),
                    class: 'ck-heading_heading_subtitle',
                    converterPriority: 'high',
                },
                {
                    model: 'headingContent',
                    view: {
                        name: 'p',
                        classes: 'content',
                    },
                    title: I18n.t('component:fullRichTextInput.content'),
                    class: 'ck-heading_heading_content',
                    converterPriority: 'high',
                },
            ],
        },
        fontFamily: {
            options: ['default', 'Open Sans'],
        },
        fontColor: {
            colors: [
                {
                    color: 'hsl(0, 0%, 14%)',
                    label: 'Black',
                },
                {
                    color: 'hsl(0, 0%, 22%)',
                    label: 'Dim grey',
                },
                {
                    color: 'hsl(0, 0%, 48%)',
                    label: 'Grey',
                },
            ],
        },
        link: {
            addTargetToExternalLinks: true,
        },
    };

    if (optionsToolbar) {
        defaultConfig.toolbar.items = optionsToolbar;
    }

    return defaultConfig;
}
