import { OfferTemplateRouteParam } from 'localTypes';

const getTradeRoutes = (type: OfferTemplateRouteParam) => ({
    posSelect: (idHolding = ':idHolding') => `/${idHolding}/${type}`,
    index: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}`,
    dailyOffer: {
        list: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/daily-offers`,
        articleEdit:
            type === OfferTemplateRouteParam.TableService
                ? (idHolding = ':idHolding', idPos = ':idPos', idOfferItem = ':idOfferItem') =>
                    `/${idHolding}/${type}/${idPos}/daily-offers/${idOfferItem}/edit`
                : () => '',
    },
    reservations: {
        list: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/daily-reservations`,
        admin: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/admin`,
    },
    catalog: {
        get: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/catalog`,
        addArticle: (idHolding = ':idHolding', idPos = ':idPos') =>
            `/${idHolding}/${type}/${idPos}/catalog/add-article`,
    },
    container: {
        get: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/container`,
        addContainer: (idHolding = ':idHolding', idPos = ':idPos') =>
            `/${idHolding}/${type}/${idPos}/container/add-container`,
    },
    pickUpPoints: {
      get: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/pickUpPoints`,
    },
    tagEditor: {
        get: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/tag-editor`
    },
    offers: {
        list: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/offers`,
        offerTemplate: {
            edit: (idHolding = ':idHolding', idPos = ':idPos', idOfferTemplate = ':idOfferTemplate') => `/${idHolding}/${type}/${idPos}/offers/${idOfferTemplate}/edit`,
            add: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/offers/add`,
        },
        listOffer: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/${type}/${idPos}/offers/daily-offers`,
        getOffer:
            type === OfferTemplateRouteParam.ClickCollect
                ? (idHolding = ':idHolding', idPos = ':idPos', idOffer = ':idOffer') =>
                    `/${idHolding}/${type}/${idPos}/offers/daily-offers/${idOffer}`
                : () => '',
        edit:
            type === OfferTemplateRouteParam.ClickCollect
                ? (idHolding = ':idHolding', idPos = ':idPos', idOffer = ':idOffer', idOfferItem = ':idOfferItem') =>
                    `/${idHolding}/${type}/${idPos}/offers/daily-offers/${idOffer}/edit/${idOfferItem}`
                : () => '',
    }
});
const routes = {
    admin: {
        index: () => '/admin',
        globalCatalog: {
            list: () => '/admin/catalog',
            article: {
                edit: (idArticle = ':idArticle') => `/admin/catalog/${idArticle}/edit`,
                add: () => `/admin/catalog/add`,
            },
        },
        users: {
            list: () => '/admin/users',
        }
    },
    holding: {
        index: (idHolding = ':idHolding') => `/${idHolding}/holding`,
        info: (idHolding = ':idHolding') => `/${idHolding}/holding/info`,
        personalization: (idHolding = ':idHolding') => `/${idHolding}/holding/personalization`,
        pos: {
            index: (idHolding = ':idHolding') => `/${idHolding}/holding/pos`,
            edit: (idHolding = ':idHolding', idPos = ':idPos') => `/${idHolding}/holding/pos/${idPos}`,
            zoneEdit: (idHolding = ':idHolding', idZone = ':idZone') => `/${idHolding}/holding/pos/zone/${idZone}`,
        },
    },
    cnc: getTradeRoutes(OfferTemplateRouteParam.ClickCollect),
    tableservice: getTradeRoutes(OfferTemplateRouteParam.TableService),
    bookingservice: getTradeRoutes(OfferTemplateRouteParam.BookingService),
    communication: {
        index: () => '/communication',
        list: () => '/communication/list',
        add: () => `/communication/list/add`,
        added: () => `/communication/list/added`,
        edit: (idCommunication = ':idCommunication') => `/communication/list/${idCommunication}/edit`,
    },
};

export default routes;
