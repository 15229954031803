import { Icon, Select } from 'components';
import { BaseButton } from 'components/Button';
import { ISelectOption } from 'components/Inputs/Select';
import React from 'react';
import styled from 'styled-components';

const options = [
    { id: '10', label: '10' },
    { id: '20', label: '20' },
    { id: '50', label: '50' },
    { id: '100', label: '100' },
];

export type rowPerPageOptions = 10 | 20 | 50 | 100;

export const DEFAULT_ROW_PER_PAGE = 100;

export interface IPageInfo {
    endCursor: any | null;
    startCursor: any | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
}
interface IProps {
    onPaginate: Function;
    pageInfo: IPageInfo;
    rowPerPage: rowPerPageOptions;
    setRowPerPage?: (r: rowPerPageOptions) => void;
}

export const resetPagination = {
    after: undefined,
    first: undefined,
    before: undefined,
    last: undefined,
};

const Paginator = ({ rowPerPage, pageInfo, onPaginate }: IProps) => {
    const onChange = (option: ISelectOption) => {
        onPaginate({ ...resetPagination, first: parseInt(option.label) as rowPerPageOptions });
    };

    const onNextClick = () => {
        onPaginate({
            ...resetPagination,
            after: pageInfo.endCursor,
            first: rowPerPage,
        });
    };
    const onPrevClick = () => {
        onPaginate({
            ...resetPagination,
            before: pageInfo.startCursor,
            last: rowPerPage,
        });
    };
    return (
        <Wrapper id='paginator'>
            <StyledSelect
                ghost
                id='paginator-select'
                selected={options.find(option => option.id === rowPerPage.toString())}
                onChange={onChange}
                data={options}
            />
            <PreviousButton id='paginator-prev-button' onClick={onPrevClick} disabled={!pageInfo.hasPreviousPage}>
                <Icon.ArrowLeft />
            </PreviousButton>
            <NextButton id='paginator-next-button' onClick={onNextClick} disabled={!pageInfo.hasNextPage}>
                <Icon.ArrowRight />
            </NextButton>
        </Wrapper>
    );
};

const StyledSelect = styled((props) => <Select newStyle={true} {...props} />)`
    width: 80px;
    max-height: 37px;
    border: 1px solid #E8ECEF;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    margin-right: ${({ theme }) => theme.spacing.xs}px;
`;

const Wrapper = styled.div`
    display: flex;
`;

const PreviousButton = styled(BaseButton)`
    background: ${({ theme }) => theme.color.grey[1]};
    &:hover:not(:disabled) path,
    &:active path {
        fill: ${({ theme }) => theme.color.common.blue};
    }
    &:disabled path {
        fill: ${({ theme }) => theme.color.grey[4]};
    }
    max-width: 37px;
    max-height: 37px;
    padding: 0px 14px;
`;

const NextButton = styled(PreviousButton)`
    margin-left: ${({ theme }) => theme.spacing.xs}px;
`;

export const PaginationWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export default Paginator;
