import { Role } from "types/globalTypes";

export const computeUserRoles = (user) => {
    const roles: Role[] = [];

    if (user.isMarketer) {
        roles.push(Role.Marketer);
    } else {
        roles.push(Role.Employee);
    }

    if (user.isAdmin) {
        roles.push(Role.Admin);
    }

    return roles;
}

export const isUserHasRole = (user, roles) => {
    const userRoles = computeUserRoles(user);
    return roles.some(role => userRoles.includes(role));
}