import { QueryLoader } from 'components';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import React, { ReactElement } from 'react';
import { RouteComponentProps, RouteProps, matchPath, Redirect, withRouter } from 'react-router-dom';
import { getItem, LocalStorageKey, removeItem, setItem } from 'services/persistentData';
import { getNodes } from 'services/queryService';
import {
    searchHolding,
    searchHolding_tokenOwner_Employee as Employee,
    searchHolding_tokenOwner_Employee_choices_edges_node,
} from 'types/searchHolding';
import routes from './routes';
import { extractPaths } from './utils';

interface IProps {
    match: {
        params: { idHolding?: string };
    };
    children: (authorizedIdHolding?: string) => ReactElement | string;
}

const HoldingIdCheck = ({
    location: { pathname },
    history,
    match: {
        params: { idHolding },
    },
    children,
}: IProps & RouteComponentProps) => {
    const persistedIdHoldingId = getItem(LocalStorageKey.HOLDING_ID);
    let currentIdHolding = persistedIdHoldingId || '';

    const paths: RouteProps[] = extractPaths(routes).map((path: string) => ({ path, exact: true, strict: false }));
    const matchingRoute = paths.find(p => !!matchPath(pathname, p));
    if (matchingRoute && matchingRoute.path && matchingRoute.path.includes(':idHolding') && idHolding) {
        currentIdHolding = idHolding;
    }

    return (
        <QueryLoader
            errorPolicy="all"
            variables={{ search: '%', id: currentIdHolding, useId: !!currentIdHolding }}
            query={loader('../query/searchHolding.gql')}
            displayErrorMessage={false}
        >
            {({ data }: QueryResult<searchHolding>) => {
                const tokenOwner = data.tokenOwner as Employee;
                if (!tokenOwner) {
                    removeItem(LocalStorageKey.HOLDING_ID);
                    return (
                        <Redirect
                            to={{
                                pathname: '/',
                            }}
                        />
                    );
                }
                const holding =
                    tokenOwner.selected && tokenOwner.selected.edges[0] && tokenOwner.selected.edges[0].node;
                const holdingList = getNodes(
                    tokenOwner.choices
                ) as searchHolding_tokenOwner_Employee_choices_edges_node[];
                const authorizedHolding = holding && holding.id === currentIdHolding ? holding : holdingList[0];

                if (!authorizedHolding) {
                    removeItem(LocalStorageKey.HOLDING_ID);
                    return (
                        <Redirect
                            to={{
                                pathname: '/',
                            }}
                        />
                    );
                }
                if (authorizedHolding.id !== persistedIdHoldingId) {
                    setItem(LocalStorageKey.HOLDING_ID, authorizedHolding.id);
                    history.replace(pathname.replace(idHolding!, authorizedHolding.id));
                }
                return children(authorizedHolding.id);
            }}
        </QueryLoader>
    );
};

export default withRouter(HoldingIdCheck);
