import React, { useState, useEffect, ReactElement, useCallback } from 'react';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import { UploadFile } from './FileInput';
import { QueryResult } from 'localTypes';
import { Icon, Text, QueryLoader, Row, Loader } from 'components';
import { appTheme } from 'theme';
import { IInputProps } from './common';

interface IImageDetailsProps {
    width: number;
    height: number;
    filename: string;
    button: ReactElement;
}
const ImageDetails = ({ width, height, filename, button }: IImageDetailsProps) => {
    return (
        <ImageInfoWrapper>
            <div style={{ flex: 15 }}>
                <Text overflow="hidden" bold>
                    {filename.length > 22 ? `${filename.substring(0, 20)}...` : filename}
                </Text>
                <div>
                    <Text>{`${width} x ${height}`}</Text>
                </div>
            </div>
            {button}
        </ImageInfoWrapper>
    );
};

interface IImageCDNPreviewProps {
    path: string;
}

const ImageCDNPreview = ({ width, height, filename, button, path }: IImageDetailsProps & IImageCDNPreviewProps) => (
    <ImageWrapper>
        <StyledImage src={`${window.config.IMAGE_BASE_URL}/resize/414x380/${path}`} />
        <ImageDetails {...{ width, height, filename, button }} />
    </ImageWrapper>
);

export const DropZoneFile = ({
    hasError,
    accept,
    onChange,
    maxFileSize = 5000000, //5Mb
    secondaryLabel,
    multipleImages = false,
    setLoading,
}: {
    selectedImages: any;
    imageList?: any;
    hasError?: boolean;
    accept: string;
    onChange?: any;
    maxFileSize?: number;
    secondaryLabel?: string;
    multipleImages?: boolean;
    setLoading: Function;
}) => {
    const [t] = useTranslation();
    const [error, setError] = useState('');

    const handleOnDrop = useCallback(
        (mutate) => async (files) => {
            setLoading(true);
            if (!files) {
                return;
            }

            const mutateList: Promise<any>[] = [];
            const formatNotAllowedError = t('component:fileInput.formatNotAllowed');
            const sizeExceededError = t('component:fileInput.sizeExceeded');

            for (const file of files) {
                if (!file.type.includes('image')) {
                    setLoading(false);
                    setError(formatNotAllowedError);
                    return;
                }
                if (file.size > maxFileSize) {
                    setLoading(false);
                    setError(sizeExceededError);
                    return;
                }

                mutateList.push(mutate({ variables: { file } }));
            }

            const responseList = await Promise.all(mutateList);
            const images: any[] = [];

            for (const res of responseList) {
                if (res && res.data) {
                    setError('');
                    images.push(res.data.upload);
                }
            }
            setLoading(false);
            onChange && onChange(images);
        },
        [setLoading, t, maxFileSize, onChange]
    );

    return (
        <UploadFile key={'uploadImage'}>
            {(mutate) => (
                <>
                    {!!secondaryLabel && (
                        <SecondaryLabelWrapper>
                            {multipleImages ? (
                                <>
                                    <div style={{ paddingTop: 5 }}>
                                        <Text  size="M">
                                            {t('page:communication.addMarketingCard.imageListHint')}
                                        </Text>
                                    </div>
                                    <div style={{ paddingTop: 5 }}>
                                        <Text size="S">{secondaryLabel}</Text>
                                    </div>
                                </>
                            ) : (
                                <Text size="S">
                                    {secondaryLabel}
                                </Text>
                            )}
                        </SecondaryLabelWrapper>
                    )}
                    <Dropzone multiple={multipleImages} onDrop={handleOnDrop(mutate)}>
                        {({ getRootProps, getInputProps }) => {
                            return (
                                <InputWrapper {...getRootProps()} hasError={hasError || !!error}>
                                    <Row justify="flex-end">
                                        <Text size="M">
                                            <Text size="M" bold>{t('component:fileInput.uploadImageBold') + ' '}</Text>
                                            {multipleImages
                                                ? t('component:fileInput.uploadMoreThenOneImage')
                                                : t('component:fileInput.uploadImage1')}
                                        </Text>
                                        <UploadButton>{t('component:fileInput.uploadImage2')}</UploadButton>
                                        <input {...getInputProps()} accept={accept} />
                                    </Row>
                                </InputWrapper>
                            );
                        }}
                    </Dropzone>
                    {!!error && (
                        <ErrorWrapper>
                            <IconWrapper>
                                <Icon.Warning color={appTheme.color.common.red} />
                            </IconWrapper>
                            <ErrorText>{error}</ErrorText>
                        </ErrorWrapper>
                    )}
                </>
            )}
        </UploadFile>
    );
};

interface IProps extends IInputProps<any> {
    id?: string;
    inline?: boolean;
    accept?: string;
    hasId?: (id: string[]) => void;
    secondaryLabel?: string;
    multipleImages?: boolean;
    imageList?: any[];
    initialImages?: any[];
}

function ImageInput({
    id,
    value,
    imageList,
    onChange,
    disabled,
    hasError,
    accept = 'image/png, image/jpeg',
    hasId,
    secondaryLabel,
    multipleImages,
    initialImages,
    ...props
}: IProps) {
    const [selectedImages, setSelectedImages] = useState<any[]>([]);
    const [dragId, setDragId] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        hasId && hasId(selectedImages);
    }, [selectedImages, hasId]);

    useEffect(() => {
        if (initialImages && initialImages.length > 0) {
            setSelectedImages(initialImages);
        }
    }, [initialImages]);

    const handleDragStart = (ev: any) => {
        setDragId(ev.currentTarget.id);
    };

    const handleDrop = (ev: any) => {
        const dragImageIndex = selectedImages.findIndex((image) => image.id === dragId);

        const dropImageIndex = selectedImages.findIndex((image) => image.id === ev.currentTarget.id);
        const dragImage = selectedImages.splice(dragImageIndex, 1);
        selectedImages.splice(dropImageIndex, 0, dragImage[0]);
        const newImageState = selectedImages.map((image, index) => ({ ...image, order: index + 1 }));

        onChange(newImageState);
        setSelectedImages(newImageState);
    };

    const removeImage = (imageId: string) => {
        let images = selectedImages;
        images.forEach((image) => {
            if (imageId && image.id === imageId) {
                images.splice(images.indexOf(image), 1);
            }
        });

        setSelectedImages([...images]);
        return;
    };

    const handleOnChange = (returnedValue: any) => {
        if (multipleImages) {
            let partialImageList: any[] = [];
            partialImageList = [...selectedImages, ...returnedValue];
            const finalImageList = partialImageList.filter((image, index) => {
                const _image = JSON.stringify(image);
                return (
                    index ===
                    partialImageList.findIndex((obj) => {
                        return JSON.stringify(obj) === _image;
                    })
                );
            });
            onChange(finalImageList);
            setSelectedImages(finalImageList);
        } else {
            onChange(returnedValue);
            setSelectedImages(returnedValue);
        }
    };

    return (
        //@ts-ignore
        <FieldWrapper id={id} {...props}>
            <DropZoneFile
                selectedImages={selectedImages}
                hasError={hasError}
                accept={accept}
                onChange={handleOnChange}
                secondaryLabel={secondaryLabel}
                multipleImages={multipleImages}
                setLoading={setLoading}
            />
            {loading ? <Loader /> : null}
            {!!selectedImages.length &&
                selectedImages.map((selectedImage: any) => {
                    return (
                        <MainImageContainer
                            id={`${selectedImage.id}`}
                            key={`div${selectedImage.id}`}
                            draggable={true}
                            onDragOver={(ev) => ev.preventDefault()}
                            onDragStart={handleDragStart}
                            onDrop={handleDrop}
                        >
                            <QueryLoader
                                key={`query${selectedImage.id}`}
                                // @ts-ignore
                                variables={{ id: selectedImage.id }}
                                query={gql`
                                    query getImage($id: ID!) {
                                        image: get(id: $id) {
                                            ... on Image {
                                                id
                                                path
                                                width
                                                height
                                                filename
                                            }
                                        }
                                    }
                                `}
                            >
                                {({ data: { image } }: QueryResult<any>) => {
                                    return (
                                        <>
                                            <ImageCDNPreview
                                                {...image}
                                                id={`image${image.id}`}
                                                key={`image${image.id}`}
                                                button={
                                                    <>
                                                        <DeleteButton
                                                            id={`deleteButton${image.id}`}
                                                            key={`deleteButton${image.id}`}
                                                            disabled={disabled}
                                                            onClick={() => {
                                                                removeImage(image.id);
                                                                onChange(selectedImages);
                                                            }}
                                                        >
                                                            <Icon.Bin
                                                                key={`deleteIcon${image.id}`}
                                                                width={20}
                                                                height={22}
                                                                color={appTheme.color.grey[6]}
                                                            />
                                                        </DeleteButton>

                                                        {selectedImages.length > 1 && (
                                                            <SortingButton
                                                                id={`sortingButton${image.id}`}
                                                                key={`sortingButton${image.id}`}
                                                                disabled={disabled}
                                                            >
                                                                <Icon.Reorder
                                                                    key={`sortingIcon${image.id}`}
                                                                    width={12}
                                                                    height={22}
                                                                    color={appTheme.color.grey[6]}
                                                                />
                                                            </SortingButton>
                                                        )}
                                                    </>
                                                }
                                            />
                                        </>
                                    );
                                }}
                            </QueryLoader>
                        </MainImageContainer>
                    );
                })}
        </FieldWrapper>
    );
}

const FieldWrapper = styled.div<IInputWrapperProps>`
    width: 100%;
    margin-top: ${({ hasError, theme }) =>
        `border: 1px solid ${hasError ? theme.color.input.borderColorError : 'none'};`};
`;

const IconWrapper = styled.div`
    margin: ${({ theme }) => `0 ${theme.spacing.xs}`};
    display: flex;
    align-content: center;
`;
interface IInputWrapperProps {
    hasError?: boolean;
}
const InputWrapper = styled.div<IInputWrapperProps>`
    display: flex;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.xxs}px;
    height: 58px;
    border-radius: ${({ theme }) => theme.borderRadius.s + 1}px;
    background-color: ${({ theme }) => theme.color.common.blue + '08'};
    cursor: pointer;
    border: 3px dashed ${({ theme }) => theme.color.common.blue + '4D'};
`;

const ImageWrapper = styled.div`
    display: flex;
    height: 80px;
    margin-top: ${({ theme }) => theme.spacing.s}px; ;
`;

const ImageInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: ${({ theme }) => theme.spacing.xxs - 3}px;
    overflow: hidden;
    background: ${({ theme }) => theme.color.grey[0]};
    width: 100%;
    padding: ${({ theme }) => theme.spacing.xs + 5}px;
    border-radius: ${({ theme }) => theme.borderRadius.s + 1}px;
`;

const StyledImage = styled.img`
    flex-shrink: 0;
    height: 80px;
    width: 80px;
    border-radius: ${({ theme }) => theme.borderRadius.m}px;
    display: flex;
    object-fit: cover;
    margin-right: ${({ theme }) => theme.spacing.xs}px;
`;

const UploadButton = styled.span`
    background: ${({ theme }) => theme.color.grey[2]};
    margin-left: ${({ theme }) => theme.spacing.xxs}px;
    padding: ${({ theme }) => `${theme.spacing.xs}px ${theme.spacing.xs - 3}px`};
    border-radius: ${({ theme }) => theme.borderRadius.m}px;
    text-align: center;
    color: ${({ theme }) => theme.color.common.blue};
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.lighter};
`;

const ErrorWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing.xs + 5}px;
`;

const MainImageContainer = styled.div``;

const ErrorText = styled.span`
    font-size: ${({ theme }) => theme.typography.fontSizeXXS + 1.2}px;
    letter-spacing: 0;
    text-align: left;
    color: ${({ theme }) => theme.color.common.red};
    font-family: inherit;
    margin-left: ${({ theme }) => theme.spacing.xs}px;
`;

const DeleteButton = styled.button`
    flex: 5;
    border: none;
    background: inherit;
`;

const SortingButton = styled.button`
    flex: 1;
    border: none;
    background: inherit;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    &:active {
        cursor: grabbing !important;
        cursor: -moz-grabbing !important;
        cursor: -webkit-grabbing !important;
    }
`;

const SecondaryLabelWrapper = styled.div`
    margin-top: -${({ theme }) => theme.spacing.xs + theme.spacing.xxs}px;
    margin-bottom: ${({ theme }) => theme.spacing.xs + theme.spacing.xxs}px;
`;

export default ImageInput;
