import React, { RefObject, useState, forwardRef } from 'react';
import styled from 'styled-components';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import '../../resources/css/datePicker.css';
import { appTheme } from '../../theme';
import { ArrowLeft, ArrowRight } from '../Icons';
import { Icon } from '../index';
import { format } from 'date-fns';

export interface IDatePickerProps {
    datePickerName: string;
    selected?: Date | null;
    onChange: (date: string) => void;
    placeholder?: string;
    disabled?: boolean;
    innerRef?: RefObject<ReactDatePicker>;
    style?: Object;
    filterDate?: (...args: any[]) => void;
    startDate?: Date | null;
    endDate?: Date | null;
    minDate?: Date | null;
    onClick?: () => void;
    selectsStart?: boolean;
    selectsEnd?: boolean;
    hasError?: boolean;
    showError?: boolean;
    highlightDates?: Date[];
    className?: string;
    onClose?: () => void;
    onMouseDown?: () => void;
}

interface IPropDatePickerInput {
    onCustomClick: () => void;
    onCustomMouseDown: () => void;
    placeholder?: string;
    hasError?: boolean;
    open: boolean;
    className?: string;
    // to allow any property - because of props 'value' and 'onClick' received from DatePicker lib
    [keys: string]: any;
}

const DatePicker: React.FC<IDatePickerProps> = (props) => {
    registerLocale('fr', fr);

    const [open, setOpen] = useState<boolean>(false);

    // forces the position of the calendar define in popperPlacement
    const datePickerPlacement = {
        flip: {
            enabled: false,
        },
        preventOverflow: {
            escapeWithReference: true,
        },
    };

    return (
        <ReactDatePicker
            locale="fr"
            showPopperArrow={false}
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            selected={props.selected}
            popperPlacement="bottom-start"
            popperModifiers={datePickerPlacement}
            dateFormat="dd/MM/yyyy"
            name={props.datePickerName}
            onChange={props.onChange}
            shouldCloseOnSelect={false}
            placeholderText={props.placeholder}
            customInput={
                <DatePickerInput
                    open={open}
                    hasError={props.hasError || props.showError}
                    className={props.className}
                    onCustomClick={() => {
                        if (props.onClick) {
                            props.onClick();
                        }
                    }}
                    onCustomMouseDown={() => {
                        if (props.onMouseDown) {
                            props.onMouseDown();
                        }
                    }}
                />
            }
            onCalendarOpen={() => {
                setOpen(true);
            }}
            onCalendarClose={() => {
                setOpen(false);
                if (props.onClose) {
                    props.onClose();
                }
            }}
            renderCustomHeader={renderCustomHeader}
            disabled={props.disabled}
            filterDate={props.filterDate}
            startDate={props.startDate}
            endDate={props.endDate}
            minDate={props.minDate}
            selectsStart={props.selectsStart}
            selectsEnd={props.selectsEnd}
            ref={props.innerRef}
            highlightDates={props.highlightDates}
            // solves the bug of the day currently selected also appear selected in the other months
            disabledKeyboardNavigation={true}
        >
            {!!props.children && <div className="col-12">{props.children}</div>}
        </ReactDatePicker>
    );
};

const DatePickerInput = forwardRef<HTMLButtonElement, IPropDatePickerInput>(
    (
        {
            value,
            placeholder,
            onClick,
            open,
            onCustomClick,
            hasError = false,
            className,
            onCustomMouseDown,
        }: IPropDatePickerInput,
        ref
    ) => {
        const [inputColor, setInputColor] = useState<string>(appTheme.color.grey[2]);

        React.useEffect(() => {
            setInputColor(hasError ? appTheme.color.common.darkRed : open ? '#68A9EF' : appTheme.color.grey[2]);
        }, [hasError, open]);

        return (
            <DatePickerInputButton
                onClick={() => {
                    onClick();
                    onCustomClick();
                }}
                color={inputColor}
                ref={ref}
                className={className}
                hasError={hasError}
                onMouseDown={onCustomMouseDown}
            >
                <>
                    <StyledIconCalendar
                        width={14}
                        height={14}
                        color={hasError ? appTheme.color.common.darkRed : appTheme.color.grey[5]}
                    />
                    <DatePickerInputText>{value || placeholder}</DatePickerInputText>
                </>
            </DatePickerInputButton>
        );
    }
);

const renderCustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
}) => {
    return (
        <DatePickerHeaderSection>
            <DatePickerArrowButton onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <ArrowLeft width={10} height={11} />
            </DatePickerArrowButton>
            <DatePickerHeaderText>{format(date, 'MMMM yyyy', { locale: fr })}</DatePickerHeaderText>
            <DatePickerArrowButton onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <ArrowRight width={10} height={11} />
            </DatePickerArrowButton>
        </DatePickerHeaderSection>
    );
};

interface IDatePickerStyleProps {
    color: string;
    hasError: boolean;
}

const DatePickerInputButton = styled.button<IDatePickerStyleProps>`
    text-align: left;
    padding: 0;
    display: flex;
    align-items: center;
    height: 45px;
    width: 170px;
    background: white;
    border: 1px solid ${({ color, hasError }) => (hasError ? `${color} !important` : color)};
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
`;
const DatePickerInputText = styled.span`
    margin-top: 2px;
    font-size: 14px;
    color: ${({ theme }) => theme.color.grey[6]};
`;
const StyledIconCalendar = styled(Icon.Calendar)`
    margin: 2px 12px 0 12px;
`;

const DatePickerHeaderSection = styled.div`
    margin: 10px;
    display: flex;
    justify-content: center;
`;
const DatePickerArrowButton = styled.button`
    width: 25px;
    height: 25px;
    background-color: ${({ theme }) => theme.color.common.transparent};
`;
const DatePickerHeaderText = styled.span`
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.fontSizeM}px;
    letter-spacing: 0;
    text-transform: capitalize;
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    width: 125px;
    margin-left: 25px;
    margin-right: 25px;
`;

export default DatePicker;
