import { QueryLoader, SelectField } from 'components';
import { Field } from 'formik';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { searchPOS, searchPOS_get, searchPOS_get_Pos, searchPOS_list_pointOfSales_pos_Pos } from 'types/searchPOS';

interface IProps {
    id?: string;
    selectedPosId?: string;
    idHolding: string | '';
    name: string;
    disabled: boolean;
    modeEdit: boolean;
    width?: number;
    color?: string;
    onChange: (value: any) => void;
    onInit?: (selectedPos: searchPOS_get) => void;
}
const SelectPOS = ({
    idHolding,
    disabled,
    name,
    modeEdit,
    width,
    color,
    id,
    selectedPosId,
    onChange,
    onInit,
    t
}: IProps & WithTranslation) => {
    const [posId] = React.useState(selectedPosId);
    const [prevSelectedPos, setPrevSelectedPos] = React.useState<searchPOS_get>();

    React.useEffect(() => {
        if (onInit && prevSelectedPos) {
            onInit(prevSelectedPos);
        }
    }, [prevSelectedPos]);

    const isPosSelectedPreviously = () => !!posId;
    const isSelectedPosFetched = () => !!prevSelectedPos;


    return (
        <QueryLoader
            context={{
                debounceKey: 'pos',
            }}
            query={loader('./searchPOS.gql')}
            // @todo: check if idHolding matches for selectedPosId
            variables={{ search: '%', idHolding, idPos: '', useId: false }}
            skip={disabled}
            omitLoader
        >
            {({ loading, data, errors, refetch }: QueryResult<searchPOS>) => {
                if (isPosSelectedPreviously() && !isSelectedPosFetched() && data.list.pointOfSales) {
                    const posNode = data.list.pointOfSales.find(node => (node.pos as any).id === selectedPosId);
                    if (posNode) {
                        setPrevSelectedPos(posNode.pos);
                    }
                }

                return (
                    <Field
                        id={id}
                        onChange={onChange}
                        placeholder={t(`app:select-pos`)}
                        loading={loading}
                        disabled={disabled || modeEdit}
                        selected={data && (data.get as searchPOS_get_Pos)}
                        data={
                            idHolding && !loading && !errors && data
                                ? data.list.pointOfSales.map(d => d.pos as searchPOS_list_pointOfSales_pos_Pos)
                                : []
                        }
                        onSearch={(search: any) => refetch({ search: `%${search}%`, idHolding })}
                        name={name}
                        component={SelectField}
                        width={width}
                        color={color}
                    />
                );
            }}
        </QueryLoader>
    );
};

export default withTranslation()(SelectPOS);
