import React, { FunctionComponent } from 'react';
import { withTheme } from 'styled-components';
import { MessageBuilder } from './messageBuilder';
import { MessageType, ErrorMessageProps } from './types';

export const ErrorMessage: FunctionComponent<ErrorMessageProps> = withTheme((props) => 
    <MessageBuilder {...props} type={MessageType.ERROR} />
);
    
export const ErrorMessageLighter: FunctionComponent<ErrorMessageProps> = withTheme((props) => 
    <MessageBuilder {...props} type={MessageType.LIGHTER} />
);

export const WarningMessage: FunctionComponent<ErrorMessageProps> = withTheme((props) => 
    <MessageBuilder {...props} type={MessageType.WARNING} />
);
