import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Tooltip, Icon } from 'components';

interface IHelpTooltipProps {
    tooltipContent?: string | ReactNode;
    iconSize?: number;
    tooltipWidth?: number;
    onClick?: () => void;
    tooltipContainerStyle?: any;
    testID?: string;
}

const HelpIcon = ({ size, onClick = () => {} }) => (
    <IconContainer size={size} onClick={onClick}>
        <Icon.HelpTooltip />
    </IconContainer>
);

const HelpTooltip = ({
    tooltipContent = '',
    iconSize = 18,
    tooltipWidth = 229,
    onClick,
    tooltipContainerStyle = {},
    testID
}: IHelpTooltipProps) => (
    <StyledTooltip
        iconSize={iconSize}
        width={tooltipWidth}
        toolTipContent={tooltipContent}
        tooltipContainerStyle={tooltipContainerStyle}
        testID={testID}
    >
        <HelpIcon size={iconSize} onClick={onClick} />
    </StyledTooltip>
);

interface IIconContainerProps {
    size: number;
}

const IconContainer = styled.div<IIconContainerProps>`
    display: flex;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: ${({ theme }) => theme.color.common.yellow};
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    cursor: pointer;
    // Bring in the hammer!
    // since we are setting the radius to 50% aligning it to the center will look a bit off
    // so by adding this padding here we can "center" it
    padding-right: 1px;
`;

const StyledTooltip = styled((props) => <Tooltip {...props} />)`
    z-index: 2;
    border-radius: 0;
    margin-top: ${({ theme }) => theme.spacing.xxs}px;
    left: ${({ width, iconSize }) => -(width / 2 - iconSize)}px;
    width: ${({ width }) => width}px;
    ${({ theme }) => `padding: ${theme.spacing.xs}px ${theme.spacing.xs + theme.spacing.xxs}px;`}

    // tooltip Text Styles
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.fontSizeXXS}px;
    line-height: ${({ theme }) => theme.typography.lineHeight.XXS}px;
    background-color: ${({ tooltipContainerStyle }) => tooltipContainerStyle.backgroundColor};
    letter-spacing: 0;
    font-weight: 500;
`;

export default HelpTooltip;
