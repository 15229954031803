import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';
import styled, { ThemeProvider } from 'styled-components';
import { toast } from 'react-toastify';
import 'services/i18n';
import { client } from 'services/apollo/client';
import { appTheme, GlobalStyle } from 'theme';
import Router from 'Router';
import { GlobalLoader } from 'components/Loader';
import { CloseButton } from 'components/ErrorToast';
import 'react-toastify/dist/ReactToastify.css';
import { BaseModalBackground, ModalProvider } from 'styled-react-modal'

toast.configure({
    hideProgressBar: true,
    toastClassName: 'app-toast',
    className: 'app-toast-container',
    closeButton: <CloseButton />,
});

// @ts-ignore
const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => 
    // @ts-ignore
    props.opacity
  };
  transition: all 0.3s ease-in-out;
`;


const App: React.FC = () => {
    return (
        <ThemeProvider theme={appTheme}>
            <ModalProvider backgroundComponent={FadingBackground}>
                <ApolloProvider client={client}>
                    <GlobalStyle />
                    <Suspense fallback={<GlobalLoader />}>
                        <Router />
                    </Suspense>
                </ApolloProvider>
            </ModalProvider>
        </ThemeProvider>
    );
};

export default App;
