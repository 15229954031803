import { Select } from 'components';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import React from 'react';
import { Query } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getNodes } from 'services/queryService';
import {
    searchHolding,
    searchHolding_tokenOwner_Employee as Employee,
    searchHolding_tokenOwner_Employee_selected_edges_node as Holding,
} from 'types/searchHolding';

interface IProps {
    match: {
        params: { idHolding?: string };
    };
}
const SelectLayoutHolding = ({
    history,
    match: {
        params: { idHolding },
    },
}: IProps & RouteComponentProps) => {
    const { t } = useTranslation();

    function onChange(selected: { id: string; label: string }): void {
        if (!idHolding) history.replace(`/${selected.id}`);
        const { pathname } = window.location;
        history.replace(pathname.replace(idHolding!, selected.id));
    }

    return (
        <Query
            context={{ debounceKey: 'holding' }}
            query={loader('../../query/searchHolding.gql')}
            variables={{ search: '%', id: idHolding || '', useId: !!idHolding }}
            errorPolicy="all"
        >
            {({ loading, data, refetch }: QueryResult<searchHolding>) => {
                let choices: Holding[] = [],
                    selected: Holding | null = null;

                try {
                    const tokenOwner = data.tokenOwner as Employee;
                    choices = getNodes(tokenOwner.choices);
                    selected = getNodes(tokenOwner.selected)[0];
                } catch (e) {}

                return (
                    <Select
                        id='holding-select'
                        onChange={onChange}
                        placeholder={t(`app:select-holding`)|| ''}
                        loading={loading}
                        selected={selected}
                        data={choices}
                        onSearch={(search: any) => refetch({ search: `%${search}%` })}
                    />
                );
            }}
        </Query>
    );
};

export default withRouter(SelectLayoutHolding);
