import React, { ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { QueryResult, Omit } from 'localTypes';
import { getNodes } from 'services/queryService';
import { QueryLoader, SearchInput, Tags, Loader } from 'components';
import { ITagOption } from 'components/Tag';
import useRoutingForTableOptions from './useRoutingForTableOptions';
import useTableOptions from './useTableOptions';
import Table, { ITableHeader, IGroupBy } from './index';
import Paginator, { rowPerPageOptions, PaginationWrapper } from './Paginator';

enum SearchKey {
    SEARCH = 'search',
}

interface IProps extends Omit<any, 'children'>, RouteComponentProps {
    headers?: ITableHeader[];
    headersWithoutPadding?: boolean;
    renderLine: Function;
    searchPlaceholder?: string;
    tags?: { [key: string]: ITagOption[] };
    children?: Function;
    // should pagination, filter and search params added to the url?
    withRouting?: boolean;
    // should pass query variables to render function (needed for cache update)
    withQueryVariables?: true;
    groupBy?: IGroupBy;
    initialBlankPage?: any;
    withSearchBar?: boolean;
    withPagination?: boolean;
    parentPage?: string;
}

interface IList {
    list: {
        edges: IEdge[];
        pageInfo: {
            endCursor: any | null;
            startCursor: any | null;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
        };
        totalCount: number;
    };
}

export interface IEdge {
    node: any;
}

const TableWithOptions = ({
    withRouting = true,
    withQueryVariables,
    children,
    location,
    history,
    headers,
    headersWithoutPadding,
    renderLine,
    variables = {},
    searchPlaceholder,
    tags,
    groupBy,
    initialBlankPage,
    withSearchBar = true,
    withPagination = true,
    parentPage,
    ...queryProps
}: IProps) => {
    let { queryParams, querySearch, onSearchValueChange, onPaginationChange, paginationOptions } = useTableOptions<
        any
    >();

    const {
        queryParams: routedQueryParams,
        querySearch: routedQuerySearch,
        onSearchValueChange: routesSearchValueChange,
        onPaginationChange: routedOnPaginationChange,
        paginationOptions: routedPaginationOptions,
    } = useRoutingForTableOptions<any>(location, history);

    if (withRouting) {
        queryParams = routedQueryParams;
        querySearch = routedQuerySearch;
        onSearchValueChange = routesSearchValueChange;
        onPaginationChange = routedOnPaginationChange;
        paginationOptions = routedPaginationOptions;
    }

    const defaultQuerySearch = variables.querySearch ? variables.querySearch : [];
    const queryVariables = {
        ...variables,
        querySearch: [...defaultQuerySearch, ...querySearch],
        ...paginationOptions,
    };
    const onSearchChange = (value: string) => {
        onSearchValueChange({ [SearchKey.SEARCH]: { key: SearchKey.SEARCH, value } });
    };

    const getFilterValue = (key: string): string[] => {
        return queryParams[key] ? queryParams[key]!.value.split(',') : ([] as string[]);
    };

    const onFilterChange = (key: string) => (value: string[]) => {
        onSearchValueChange({
            [key]: value.length > 0 ? { key, value: value.join(), operator: '*' } : undefined,
        });
    };
    const tableHasData = (data: IList): boolean => !!(data && data.list!);
    return (
        <QueryLoader
            context={{
                debounceKey: 'list',
                debounceTimeout: 500
            }}
            {...queryProps}
            variables={queryVariables}
            hasData={tableHasData}
        >
            {(queryResultProps: QueryResult<IList>) => {
                const {
                    data,
                    data: { list },
                    loading,
                } = queryResultProps;
                const tagKeys = tags && Object.keys(tags);

                return (
                    <Wrapper id='table'>
                        {(!initialBlankPage || data.list.totalCount > 0 || queryParams.search?.value) && (
                       <>
                        {withSearchBar && (
                            <StyledSearchInput
                                value={queryParams.search ? queryParams.search.value : ''}
                                onChange={onSearchChange}
                                placeholder={searchPlaceholder || ''}
                                {...(parentPage && {testID:`${parentPage}-search-input`})}
                            />
                        )}
                        {tagKeys && (
                            <TagWrapper 
                              id='tags'
                              {...(parentPage && {'data-test':`${parentPage}-filter-tags`})}
                            >
                                {tagKeys.map((key) => (
                                    <Tags
                                        wrapped={false}
                                        key={key}
                                        options={tags![key]}
                                        value={getFilterValue(key)}
                                        onChange={onFilterChange(key)}
                                    />
                                ))}
                            </TagWrapper>
                        )}
                        {withPagination && (
                            <PaginationWrapper
                              id='pagination'
                              {...(parentPage && {'data-test':`${parentPage}-pagination`})}
                            >
                                <Paginator
                                    pageInfo={list.pageInfo}
                                    onPaginate={onPaginationChange}
                                    rowPerPage={(paginationOptions.first || paginationOptions.last) as rowPerPageOptions}
                                />
                            </PaginationWrapper>
                        )}
                        {
                          (loading && tableHasData(data)) ? <StyledLoader /> :
                          <Table
                            groupBy={groupBy}
                            renderLine={
                                (withQueryVariables ? renderLine(queryVariables) : renderLine) as (
                                    item: any,
                                    index: number
                                ) => ReactElement
                            }
                            data={getNodes(list)}
                            headers={headers}
                            headersWithoutPadding={headersWithoutPadding}
                            {...(parentPage && {testID:`${parentPage}-products-table`})}
                          />
                        }
                        {children && children(queryResultProps)}
                        </>
                        )}
                        {initialBlankPage && data.list.totalCount === 0 && initialBlankPage()}
                    </Wrapper>
                );
            }}
        </QueryLoader>
    );
};

const StyledSearchInput = styled(SearchInput)`
    margin-bottom: ${({ theme }) => theme.spacing.xs}px;
`;

const TagWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
`;

const StyledLoader = styled(Loader)`
    left: 0px;
    top: 50px;
`;

const Wrapper = styled.div`
    position: relative;
`;

export default withRouter(TableWithOptions);
