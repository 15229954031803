import React, { useState, useEffect } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { loader } from 'graphql.macro';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getArticleFamilyOptions } from 'services/articleService';
import { Button, NumberInput, TableWithOptions, Icon } from 'components';
import { ButtonType, IconButton } from 'components/Button';
import { updateOfferItemVariables } from 'types/updateOfferItem';
import { listOfferItems, listOfferItems_list_edges_node_OfferItem } from 'types/listOfferItems';
import LocalCatalogTablePanel from '../../../../components/Layout/LocalCatalogTablePanel';
import { QueryResult } from 'localTypes';
import { StyledTooltip } from 'pages/Communication/CommunicationPage/CommunicationListTable';
import { TransitionGroup } from 'react-transition-group';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import EditOfferItemPanel from 'pages/TableService/DailyOffersPage/EditOfferItemPanel';
import OfferItemsToggle from 'pages/ClickCollect/DailyOffersPage/OfferDetailsPage/OfferItemsToggle';
import { OfferItemToSell } from 'types/globalTypes';

enum QueryParamKeys {
    FAMILY = 'article.family',
}

const LIST_OFFER_ITEMS_QUERY = loader('../query/listOfferItems.gql');
const UPDATE_OFFER_ITEM_MUTATION = loader('../../../../query/updateOfferItem.gql');
const ICON_WIDTH = 20;

type updatedListTableServiceOfferItem = {
    value: number | string;
    id: string;
    quantityOverall: number;
};

function OfferItem({
    item: { inheritedLabel, quantityRemaining, quantityOverall, id, localArticle, toSell },
    valuesAtZero,
    onChange,
    onSubmit,
    onClick,
    hasContainerColumn = false,
    onToSellUpdate,
}: {
    item: listOfferItems_list_edges_node_OfferItem;
    valuesAtZero: boolean;
    onChange: (item: updatedListTableServiceOfferItem) => void;
    onSubmit: (item: updatedListTableServiceOfferItem) => void;
    hasContainerColumn: boolean;
    onClick: () => void;
    onToSellUpdate: (id: string, toSell: OfferItemToSell) => void;
}) {
    const [numberInput, setNumberInput] = useState<number | string>(0);
    useEffect(() => {
        if (valuesAtZero) {
            setNumberInput(0);
        }
    }, [valuesAtZero]);
    return (
        <>
            <td
                style={{
                    width: hasContainerColumn ? '25%' : '30%',
                    overflowWrap: 'anywhere',
                }}
            >
               {inheritedLabel}
            </td>
            {hasContainerColumn && (
                <td style={{ width: '5%' }}>
                    <TdWrapper>
                        {localArticle.container !== null && (
                            <StyledTooltip toolTipContent={localArticle.container.label}>
                                <Icon.Container width={ICON_WIDTH} height={ICON_WIDTH} />
                            </StyledTooltip>
                        )}
                    </TdWrapper>
                </td>
            )}
            <td style={{ width: '25%' }}>
                <TdWrapper>
                    <NumberInput
                        value={numberInput}
                        onChange={(value: number | string) => {
                            setNumberInput(value);
                            const finalValue = typeof value === 'number' ? value : 0;
                            onChange({ id, quantityOverall, value: finalValue });
                        }}
                    />
                </TdWrapper>
            </td>
            <td style={{ width: '15%' }}>
                <TdWrapper>
                    <QuantityWrapper>{quantityRemaining}</QuantityWrapper>
                </TdWrapper>
            </td>
            <td style={{ width: '10%' }}>
                <TdWrapper>
                    <OfferItemsToggle
                       id={id}
                       toSell={toSell}
                       cashSystemCode={localArticle.cashSystemCode}
                       onToSellUpdate={onToSellUpdate}
                    />
                </TdWrapper>
            </td>
            <td style={{ width: '20%' }}>
                <ButtonWrapper>
                    {!numberInput && (
                        <IconButton onClick={onClick}>
                            <Icon.SolidArrow />
                        </IconButton>
                    )}
                    {!!numberInput && typeof numberInput === 'number' && (
                        <Button
                            inline
                            onClick={() => {
                                onSubmit({ id, quantityOverall, value: numberInput });
                                setNumberInput(0);
                            }}
                            display={ButtonType.VALIDATION}
                        >
                            VALIDER
                        </Button>
                    )}
                </ButtonWrapper>
            </td>
        </>
    );
}

interface IProps {
    idOffer: string;
    toggleCatalogModal: () => void;
    isCatalogPanelOpen: boolean;
    shouldRefetchItems: boolean;
    setShouldRefetchItems: (param: boolean) => void;
}

const OfferItemTable = ({
    idOffer,
    toggleCatalogModal,
    isCatalogPanelOpen,
    shouldRefetchItems,
    setShouldRefetchItems,
    history,
    location,
    match: {
        params: { 
          // @ts-ignore
          idHolding, idPos 
        },
    },
}: IProps & RouteComponentProps) => {
    const { t } = useTranslation();
    const [updatedValues, setUpdatedValues] = useState<updatedListTableServiceOfferItem[]>([]);
    const [valuesAtZero, setValuesAtZero] = useState<boolean>(true);
    const [columnName, setColumnName] = useState<string>('');

    return (
        <Mutation mutation={UPDATE_OFFER_ITEM_MUTATION}>
            {(updateOfferItem: (param: Record<'variables', updateOfferItemVariables>) => Promise<any>) => {
                const onUpdate = (list: updatedListTableServiceOfferItem[], multiLine: boolean) => {
                    list.forEach((el: updatedListTableServiceOfferItem) => {
                        const { id, quantityOverall, value } = el;
                        const finalQuantity = typeof value === 'number' ? quantityOverall + value : quantityOverall;
                        updateOfferItem({
                            variables: {
                                id,
                                quantityOverall: finalQuantity,
                            },
                        });
                    });
                    if (multiLine) {
                        setUpdatedValues([]);
                        setValuesAtZero(true);
                    }
                };

                const onToSellUpdate = (id: string, toSell: OfferItemToSell) => {
                    updateOfferItem({
                        variables: {
                            id,
                            toSell,
                        },
                    });
                };

                const renderLine = (item: listOfferItems_list_edges_node_OfferItem, key: number) => {
                    if (item.localArticle.container !== null) {
                        setColumnName(t('page:clickcollect.daily-offers.table.header.type') || '');
                    } else if (key === 0) {
                        setColumnName('');
                    }

                    return (
                        <tr key={item.id}>
                            <OfferItem
                                item={item}
                                valuesAtZero={valuesAtZero}
                                onClick={() => {
                                    history.push(
                                        `${routes.cnc.offers.edit(idHolding, idPos, idOffer, item.id)}${
                                            location.search
                                        }`
                                    );
                                }}
                                onChange={(item: updatedListTableServiceOfferItem) => {
                                    const tempState = updatedValues.filter(
                                        (el: updatedListTableServiceOfferItem) => el.id !== item.id
                                    );
                                    setValuesAtZero(false);
                                    if (item.value === 0) {
                                        setUpdatedValues([...tempState]);
                                    } else {
                                        setUpdatedValues([...tempState, item]);
                                    }
                                }}
                                onSubmit={(item) => {
                                    const newState = updatedValues.filter(
                                        (el: updatedListTableServiceOfferItem) => el.id !== item.id
                                    );
                                    onUpdate([item], false);
                                    setUpdatedValues(newState);
                                }}
                                hasContainerColumn={columnName.length > 0}
                                onToSellUpdate={onToSellUpdate}
                            />
                        </tr>
                    );
                };

                const defaultQuerySearch = [{ key: 'idOffer', value: idOffer }];

                const getHeaders = (t: any) => [
                    {
                        key: 'inheritedLabel',
                        displayName: t('schema:offerItem.inheritedLabel'),
                    },
                    columnName.length > 0 ? {
                        key: 'type',
                        displayName: columnName,
                    } : null,
                    {
                        key: 'add-stock',
                        displayName: t('page:clickcollect.daily-offers.table.header.stock'),
                    },
                    {
                        key: 'quantityRemaining',
                        displayName: t('schema:offerItem.quantityRemaining'),
                    },

                    {
                        key: 'toSell',
                        displayName: t('schema:offerItem.toSell'),
                    },
                    {
                        key: 'validate',
                        withoutPadding: true,
                        displayName:
                            updatedValues.length > 1 ? (
                                <ValidateAll>
                                    <ValidateAllButtonWrapper>
                                        <Button
                                            onClick={() => onUpdate(updatedValues, true)}
                                            display={ButtonType.VALIDATION}
                                            shortVersion={columnName.length > 0}
                                        >
                                            {t('schema:offer.validateAll')}
                                        </Button>
                                    </ValidateAllButtonWrapper>
                                </ValidateAll>
                            ) : (
                                ''
                            ),
                    },
                ].filter(item => item !== null);

                return (
                    <TableWithOptions
                        withRouting={false}
                        renderLine={renderLine}
                        headers={getHeaders(t)}
                        pollInterval={30000}
                        query={LIST_OFFER_ITEMS_QUERY}
                        searchPlaceholder={t('app:placeholder.search.article')}
                        variables={{ querySearch: defaultQuerySearch }}
                        tags={{
                            [QueryParamKeys.FAMILY]: getArticleFamilyOptions(),
                        }}
                        groupBy={{
                            key: 'inheritedFamily',
                            options: getArticleFamilyOptions(true, t(`schema:article.familyOption.OTHER`)  || ''),
                        }}
                    >
                        {({ refetch }: QueryResult<listOfferItems>) => {
                            // this value is set as true after we duplicate the offer items
                            // we must refetch the new items after doing it
                            if (shouldRefetchItems) {
                                setShouldRefetchItems(false);
                                refetch();
                            }
                            const closeModal = () => {
                                refetch();
                                toggleCatalogModal();
                            };
                            return (
                                <>
                                    <LocalCatalogTablePanel
                                        idOffer={idOffer}
                                        closeModal={closeModal}
                                        isOpen={isCatalogPanelOpen}
                                    />
                                    <TransitionGroup>
                                        <Route
                                            path={`${routes.cnc.offers.edit()}`}
                                            render={() => <EditOfferItemPanel beforeClose={refetch} />}
                                        />
                                    </TransitionGroup>
                                </>
                            );
                        }}
                    </TableWithOptions>
                );
            }}
        </Mutation>
    );
};

const ButtonWrapper = styled.div`
    width: 100px;
    margin: auto;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const QuantityWrapper = styled.div`
    text-align: center;
    width: 50%;
`;

const ValidateAll = styled.div`
    position: relative;
`;

const ValidateAllButtonWrapper = styled.div`
    width: 100%;
    position: relative;
`;

const TdWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;



export default withRouter(OfferItemTable);
