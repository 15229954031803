import React from 'react';
import { Button, Title, Icon, Row, TextInputField, Toggle } from 'components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { ButtonType } from 'components/Button';

interface IProps {
    field: any;
    form: any;
    asRow: boolean;
    disabled: boolean;
}

const MenuCategoriesEdit: React.FC<IProps> = React.memo(({ field, form, asRow, disabled }) => {
    const [t] = useTranslation();

    const idCategoryMenus = field?.value?.length ? field.value : [];

    const onChange = (value: number[]) => {
        form.setFieldValue(field.name, value);
        form.setFieldTouched(field.name, true);
    };

    const onAdd = () => {
        onChange([
            ...idCategoryMenus,
            null
        ]);
    };
    const onRemove = (indexToRemove) => () => {
        onChange(idCategoryMenus.filter((_, index) =>  index !==indexToRemove));
    };

    const onEditMenuId = (index: number) => (inputValue?: any) => {
        onChange(idCategoryMenus.map((id: number, i: number) => ((i === index) ? parseInt(inputValue?.value) : id)));
    };
    return (
        <Wrapper>
            <Row>
                <Title
                    mode="H3"
                    value={t('schema:pos.menuCategory')}
                >
                    <Button
                        disabled={disabled}
                        type="button"
                        display={disabled ? ButtonType.DISABLED : ButtonType.SECONDARY}
                        onClick={onAdd}
                        inline
                    >
                        + {t('page:communication.addMarketingCard.addComponent')}
                    </Button>
                </Title>
            </Row>
            {idCategoryMenus?.map((id: number, index: number) => (
                <ExternalLinkContainer key={index} asRow={asRow}>
                    <Row align="flex-start">
                        <FieldWrapper>
                            <Field
                                disabled={disabled}
                                key={`id_${index}`}
                                id={`id_${index}`}
                                name={`id_${index}`}
                                component={TextInputField}
                                maxLength={30}
                                value={id}
                                type={'number'}
                                onChange={onEditMenuId(index)}
                            />
                            <Delete onClick={onRemove(index)}>
                                <Icon.Bin />
                            </Delete>
                        </FieldWrapper>
                    </Row>
                </ExternalLinkContainer>
            ))}
        </Wrapper>
    );
});

const Wrapper = styled.div`
    width: 100%;
`;

const FieldWrapper = styled.div<{ asRow?: boolean }>`
    width: 100%;
    margin-right: ${({ asRow }) => asRow === undefined ? 0 : 26}px;
    min-width: 150px;
    height: 32px;
    display: flex;
`;

const ExternalLinkContainer = styled.div<{ asRow?: boolean }>`
    margin-bottom: ${({ asRow }) => asRow ? -20 : 30}px;
`;

const Delete = styled.a`
    padding: ${({ theme }) => theme.spacing.xs}px;
    cursor: pointer;
`;

export default MenuCategoriesEdit;
