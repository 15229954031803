import { Column, InfoLabel, Row, SelectField, Tags, ImageInputField } from 'components';
import CheckBoxWithLabel from 'components/CheckBoxWithLabel';
import { Field } from 'formik';
import { ParagraphComponent } from 'pages/Communication/AddOrEditForm/components/ParagraphComponent';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { langs } from 'services/translationService';
import styled from 'styled-components';
import { appTheme } from 'theme';
import * as Yup from 'yup';
import SelectPOS from '../../../../feature/SelectPOS';
import { OfferTemplateSalesTypeDic, OfferTemplateWithdrawalTypeDic } from './index';
import { OfferTemplateSalesType, OfferTemplateWithdrawalType } from 'types/globalTypes';
import LanguageTextInput from 'components/LanguageTextInput';
import { WarningMessage } from 'components/ErrorMessages';
import { TextLighter } from '../../../../components/ErrorMessages/styledComponents';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';

const GET_POS_PLC = loader('../query/getPosPlc.gql');

export interface ISelectOption {
    id: string;
    label: string;
    zone: any;
}
interface IStepProps {
    values?: any;
    validate?: Function;
    validationSchema?: Yup.ObjectSchema<any>;
    setStepSchema: Function;
    isModeEdit: boolean;
    setFieldValue?: Function;
    setFieldTouched?: Function;
    setSalesType?: Function;
    idHolding: string;
    idPos: string;
    setWithdrawalType?: Function;
}

const StepOne = ({
    values,
    setStepSchema,
    isModeEdit,
    setFieldValue,
    setFieldTouched,
    setSalesType,
    idPos,
    setWithdrawalType
}: IStepProps) => {
    // enable Cat based on Pos settings
    const { data } = useQuery(GET_POS_PLC, {
        variables: {
            idPos: !values.pos ? idPos : values.pos,
        },
        fetchPolicy: 'network-only',
    });

    //Being used to change dynamically the number of steps that the user will be going through
    useEffect(() => {
        setSalesType && setSalesType(values.salesType);
    }, [setSalesType, values.salesType]);

    useEffect(() => {
        setWithdrawalType && setWithdrawalType(values.withdrawalType)
    }, [setWithdrawalType, values.withdrawalType]);

    //form & FieldProps
    const { t } = useTranslation();
    const [languages, setLanguages] = useState<string[]>(values.languages);
    const [hasTranslations, setHasTranslations] = useState(values.hasTranslations);
    const [hasFullDescription, setHasFullDescription] = useState(values.hasFullDescription);
    const [idImage, setIdImage] = useState(values.image && values.image.id !== '' ? values.image : null);
    const [idCashSystem, setIdCashSystem] = useState(null);

    const translationNameComponents = useMemo(() => {
        return languages.map((lang) => {
            let indexOfCurrentLanguage = values.translations.findIndex((translation) => translation.language === lang);

            return (
                <LanguageTextInput
                    id={`offer-name-input-${lang}`}
                    key={`name${lang}`}
                    locale={lang}
                    name={`translations[${indexOfCurrentLanguage}].name`}
                    style={{ height: 40 }}
                />
            );
        });
    }, [languages, values.translations]);

    const translationDescriptionComponents = useMemo(() => {
        return languages.map((lang) => {
            let indexOfCurrentLanguage = values.translations.findIndex((translation) => translation.language === lang);

            return (
                <LanguageTextInput
                    id={`offer-description-input-${lang}`}
                    key={`description${lang}`}
                    locale={lang}
                    name={`translations[${indexOfCurrentLanguage}].description`}
                    multiline
                />
            );
        });
    }, [languages, values.translations]);

    const translationFullDescriptionComponents = useMemo(() => {
        return languages.map((lang) => {
            let indexOfCurrentLanguage = values.translations.findIndex((translation) => translation.language === lang);

            return (
                <ParagraphComponent
                    id={`offer-fullSescription-input-${lang}`}
                    rank={1}
                    key={`fullDescription${lang}`}
                    lang={lang}
                    name={`translations[${indexOfCurrentLanguage}].fullDescription`}
                />
            );
        });
    }, [languages, values.translations]);

    const translationHelpContentComponents = useMemo(() => {
        return languages.map((lang) => {
            let indexOfCurrentLanguage = values.translations.findIndex((translation) => translation.language === lang);

            return (
                <ParagraphComponent
                    id={`offer-helpContent-input-${lang}`}
                    rank={1}
                    key={`onlineHelp${lang}`}
                    lang={lang}
                    name={`translations[${indexOfCurrentLanguage}].onlineHelp`}
                />
            );
        });
    }, [languages, values.translations]);

    const handleChangeInLanguages = (selectedOptions: string[], pressedValue: string) => {
        //Logic to add new content to form values, if added new language

        const first = 'fr';
        selectedOptions.sort((x, y) => {
            return x === first ? -1 : y === first ? 1 : 0;
        });

        selectedOptions.forEach((option) => {
            const indexElementWithNewLanguage = values.translations.findIndex(
                (contentOnline) => contentOnline.language === option
            );

            if (indexElementWithNewLanguage === -1) {
                const obj = {
                    language: option,
                    name: '',
                    description: '',
                    fullDescription: '',
                    onlineHelp: null,
                };

                const translations = [...values.translations, obj];
                setFieldValue && setFieldValue('translations', translations);
            }
        });

        if (selectedOptions.length < values.translations.length) {
            const filteredTranslations = values.translations.filter(({ language }) => pressedValue !== language);
            setFieldValue && setFieldValue('translations', filteredTranslations);
        }

        setLanguages(selectedOptions);
        setFieldValue && setFieldValue('languages', selectedOptions);
        setFieldTouched && setFieldTouched('languages', true);
    };

    const handleChangeInHasOnlineContent = () => {
        values.hasTranslations = !hasTranslations;
        setHasTranslations(!hasTranslations);
    };

    const handleChangeInHasFullDescription = () => {
        values.hasFullDescription = !hasFullDescription;

        if (!hasFullDescription === false) {
            values.fullDescription = '';
        }

        setHasFullDescription(!hasFullDescription);
    };

    useEffect(() => {
        setStepSchema(
            Yup.object().shape({
                holding: Yup.string().required(t('page:admin.cnc.required') || undefined),
                pos: Yup.string().required(t('page:admin.cnc.required') || undefined),
                period: Yup.string().required(t('page:admin.cnc.required') || undefined),
                salesType: Yup.string().required(t('page:admin.cnc.required') || undefined),
                withdrawalType: Yup.string().required(t('page:admin.cnc.required') || undefined),
                name: Yup.string(),
                translations: Yup.array().of(
                    Yup.object().shape({
                        name: Yup.string().required(t('page:admin.cnc.required') || undefined),
                    })
                ),
                image: Yup.object({
                    id: Yup.string().required(t('page:admin.cnc.required') || undefined),
                }),
                description: Yup.string(),
                frDescription: Yup.string(),
                enDescription: Yup.string(),
                fullDescription: Yup.string(),
                frFullDescription: Yup.string(),
                enFullDescription: Yup.string(),
            })
        );
    }, [setStepSchema, t]);

    /**
     * With useMemo it will avoid re-calculating the warning component message at every update.
     * It will compute the value, just once, at on mount.
     *
     */
    const displayOfferConfigurationWarning = useMemo(() => {
        const mailToSupportURI = `mailto:${window.config.SUPPORT_CONTACT}`;
        const subject = t('page:admin.cnc.offerConfigurationWarningEmailSubject');

        return (
            <WarningWrapper>
                <WarningMessage renderChildrenAsIs={true}>
                    <TextLighter>
                        {t('page:admin.cnc.offerConfigurationWarning')}&nbsp;
                        <a
                            style={{ color: appTheme.color.common.blue }}
                            href={`${mailToSupportURI}?subject=${subject}`}
                            title={mailToSupportURI}
                        >
                            {t('page:admin.cnc.serviceSupport')}
                        </a>
                    </TextLighter>
                </WarningMessage>
            </WarningWrapper>
        );
    }, [t]);

    return (
        <Wrapper>
            <SubWrapper>
                <LanguagesWrapper>
                    <InfoLabel inline fontWeight="bold" name={t('page:communication.addMarketingCard.languages')} paddingRight={4} />
                    <InfoLabel inline fontColor={appTheme.color.common.darkRed} name="*" />
                    <Tags options={langs} value={languages} onChange={handleChangeInLanguages} fontSize="S" />
                </LanguagesWrapper>

                <Row>
                    <InfoLabel
                        inline
                        fontWeight="bold"
                        name={t(`page:admin.cnc.selectPOS`)}
                        paddingRight={4}
                        paddingBottom={15}
                    />
                    <InfoLabel inline fontColor={appTheme.color.common.darkRed} name="*" paddingBottom={15} />
                </Row>
                <WrapperInput id="selectPOS-input">
                    <SelectPOS
                        id="selectPOS"
                        name="pos"
                        idHolding={values.holding}
                        disabled={!values.holding}
                        selectedPosId={values.pos as string}
                        modeEdit={isModeEdit}
                        onChange={(pos: ISelectOption) => {
                            setFieldValue && setFieldValue('posName', pos.label);
                            setIdCashSystem(pos.zone.holding.idCashSystem)
                            values.withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE &&
                                setFieldValue &&
                                setFieldValue('withdrawalType', '');
                        }}
                        onInit={(pos: any) => {
                            setIdCashSystem(pos.zone.holding.idCashSystem)
                        }}
                        width={460}
                        color={appTheme.color.grey[5]}
                    />
                </WrapperInput>

                {languages.length > 0 && (
                    <>
                        <Row>
                            <InfoLabel
                                inline
                                fontWeight="bold"
                                name={t(`page:admin.cnc.offerName`)}
                                paddingRight={4}
                                paddingBottom={15}
                            />
                            <InfoLabel inline fontColor={appTheme.color.common.darkRed} name="*" paddingBottom={15} />
                        </Row>
                        {translationNameComponents}
                    </>
                )}

                <Row>
                    <InfoLabel
                        inline
                        fontWeight="bold"
                        name={t(`page:admin.cnc.salesType`)}
                        paddingRight={4}
                        paddingBottom={14}
                    />
                    <InfoLabel inline fontColor={appTheme.color.common.darkRed} name="*" paddingBottom={15} />
                </Row>
                <WrapperInput id="salesType-input">
                    <Field
                        id="salesType"
                        name="salesType"
                        disabled={isModeEdit}
                        onChange={(type: ISelectOption) => {
                            setFieldValue && setFieldValue('salesType', type.id);
                        }}
                        data={OfferTemplateSalesTypeDic().map((d) => d as ISelectOption)}
                        component={SelectField}
                        width={460}
                        color={appTheme.color.grey[5]}
                    />
                </WrapperInput>
                {values.salesType === OfferTemplateSalesType.BUNDLE ? displayOfferConfigurationWarning : null}
                <Row>
                    <InfoLabel
                        inline
                        fontWeight="bold"
                        name={t(`page:admin.cnc.withdrawalType`)}
                        paddingRight={4}
                        paddingBottom={15}
                    />
                    <InfoLabel inline fontColor={appTheme.color.common.darkRed} name="*" paddingBottom={15} />
                </Row>
                <WrapperInput id="withdrawalType-input">
                    <Field
                        id="withdrawalType"
                        name="withdrawalType"
                        disabled={isModeEdit}
                        onChange={(type: ISelectOption) => {
                            setFieldValue && setFieldValue('withdrawalType', type.id);
                            // necessary to clean the orderStartDefaultDaily, to initialize the value properly based on the withdrawal type
                            setFieldValue && setFieldValue('orderStartDefaultDaily', null);
                        }}
                        data={
                            OfferTemplateWithdrawalTypeDic(false).filter(
                                (wType) => idCashSystem === 1
                                    && data?.pos?.hasCat
                                    || (wType.id !== OfferTemplateWithdrawalType.TABLE_SERVICE)
                                    || values.withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE
                            )
                        }
                        component={SelectField}
                        width={460}
                        resultSize={12}
                        color={appTheme.color.grey[5]}
                    />
                </WrapperInput>

                {languages.length > 0 && (
                    <>
                        <Row>
                            <InfoLabel fontWeight="bold" name={t(`page:admin.cnc.description`)} paddingRight={4} />
                            {/* There is no limit on the description in the backend, if added re-nable this and update translations */}
                            <InfoLabel fontStyle="italic" name={t(`page:admin.cnc.maximumChracters`, { max: 240 })} />
                        </Row>
                        {translationDescriptionComponents}
                    </>
                )}

                <Field
                    id="image-input"
                    label={t(`page:admin.cnc.OfferImage`)}
                    component={ImageInputField}
                    name="image"
                    hasId={(idList: any[]) => {
                        if (idList.length > 0) {
                            let obj;
                            idList.forEach((object) => {
                                delete object.created;
                                delete object.hash;
                                delete object.path;
                                delete object.__typename;
                                obj = object;
                            });
                            values.image = obj;
                        } else {
                            values.image = { id: '' };
                            setIdImage(null);
                        }
                    }}
                    initialImages={idImage ? [idImage] : []}
                    overrideShowRequiredAsterisk
                    fontWeight="bold"
                />
            </SubWrapper>
            <VerticalLine />
            <SubWrapper>
                {languages.length > 0 && (
                    <>
                        {/* There is no limit on the full description in the backend, if added re-enable this and update translations */}
                        <CheckBoxWithLabel
                            id="fullDescription-checkBox"
                            checked={hasFullDescription}
                            onChange={handleChangeInHasFullDescription}
                            label={t(`page:admin.cnc.fullDescription`)}
                            secondaryLabel={t(`page:admin.cnc.fullDescriptionSubtitle`)}
                        />
                        {hasFullDescription && translationFullDescriptionComponents}
                    </>
                )}
                <br />

                {languages.length > 0 && (
                    <>
                        <CheckBoxWithLabel
                            id="onlineHelp-checkBox"
                            checked={hasTranslations}
                            onChange={handleChangeInHasOnlineContent}
                            label={t(`page:admin.cnc.onlineHelp`)}
                            secondaryLabel={t(`page:admin.cnc.onlineHelpSubtitle`)}
                        />
                        {hasTranslations && translationHelpContentComponents}
                    </>
                )}
            </SubWrapper>
        </Wrapper>
    );
};

const LanguagesWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
`;

export const Wrapper = styled.div`
    align-items: flex-start;
    padding: ${({ theme }) => theme.spacing.s}px;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    background-color: ${({ theme }) => theme.color.common.white};
    flex-grow: 0;
    justify-content: center;
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const SubWrapper = styled(Column)`
    margin: 0 20px;
    align-items: flex-start;
    width: 460px;
`;

export const VerticalLine = styled.div`
    border-left: 2px solid ${() => appTheme.color.grey[0]};
    height: calc(100% - 20px);
    position: flex;
    left: 50%;
    margin-left: -3px;
    top: 0;
`;

const WrapperInput = styled.div`
    position: flex;
    flex-grow: 0;
    justify-content: start;
    align-items: flex-start;
    display: flex;
    left: 50%;
`;

const WarningWrapper = styled.div`
    margin-top: -20px;
    margin-bottom: 20px;
`;

export default StepOne;
