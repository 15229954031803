import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { RouteProps } from 'react-router-dom';
import Auth from 'services/auth0';
import User from 'components/Query/User';
import { GlobalLoader } from 'components/Loader';
import { PageContainer, HoldingPageContainer } from 'components/Layout';
import Demo from 'pages/Demo';
import ClickCollect from 'pages/ClickCollect';
import TableService from 'pages/TableService';
import Holding from 'pages/Holding';
import Admin from 'pages/Admin';
import CallBack from './Auth/CallBack';
import Login from './Auth/Login';
import appRoutes from './routes';
import HoldingIdCheck from './HoldingIdCheck';
import BookingService from 'pages/Reservations';
import Communication from 'pages/Communication';
import { isUserHasRole } from 'services/user';
import { Role } from 'types/globalTypes';
import { whoami_tokenOwner_Employee } from 'types/whoami';

export const AdminRoute = ({ ...props }: RouteProps) => (
    <User>
        {({ user }) => {
            return user && user.isAdmin ? <Route {...props} /> : null;
        }}
    </User>
);

export const RouteWithRoles = ({ roles, ...props }: RouteProps & { roles: Role[] }) => (
    <User>
        {({ user }) => {
            return isUserHasRole(user, roles) ? <Route {...props} /> : null;
        }}
    </User>
);

class RouterComponent extends Component<{}, { loading: boolean }> {
    state = { loading: true };
    async componentDidMount() {
        try {
            await Auth.silentAuth();
        } catch (err) {
          // @ts-ignore
            if (err.error !== 'login_required') console.log(err.error);
        }
        this.setState({ loading: false });
    }
    render() {
        return (
            <Router>
                {!this.state.loading ? (
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route path="/callback" component={CallBack} />
                        <Route path="/demo" component={Demo} exact />
                        <Route
                            path="/:idHolding?"
                            render={(props) => {
                                return Auth.isAuthenticated() ? (
                                    <MainLayout />
                                ) : (
                                    <Redirect
                                        to={{
                                            pathname: '/login',
                                            state: { from: props.location },
                                        }}
                                    />
                                );
                            }}
                        />
                    </Switch>
                ) : (
                    <GlobalLoader />
                )}
            </Router>
        );
    }
}

const getDefaultPage = (user: whoami_tokenOwner_Employee | undefined, idHolding) => {
    if (!user) {
        return appRoutes.cnc.posSelect(idHolding);
    }

    if (user.isAdmin) {
        return appRoutes.admin.index();
    }

    if (user.isMarketer) {
        return appRoutes.communication.list();
    }

    return appRoutes.cnc.posSelect(idHolding);
}

const MainLayout = () => (
    <User>
        {({ user }) => (
            <HoldingIdCheck>
                {(idHolding) => (
                    <PageContainer>
                        <Switch>
                            <Route
                                exact
                                path="/"
                                render={() => <Redirect to={getDefaultPage(user, idHolding)} />}
                            />
                            <AdminRoute component={Admin} path={`${appRoutes.admin.index()}/:tab?`} />
                            <RouteWithRoles component={Communication} roles={[Role.Admin, Role.Marketer]} path={`${appRoutes.communication.list()}`} />
                            <RouteWithRoles path="/:idHolding" roles={[Role.Admin, Role.Employee]}>
                                <HoldingPageContainer>
                                    <Switch>
                                        <AdminRoute component={Holding} path={`${appRoutes.holding.index()}/:tab?`} />
                                        <Route component={ClickCollect} path={`${appRoutes.cnc.posSelect()}/:idPos?`} />
                                        <Route
                                            component={TableService}
                                            path={`${appRoutes.tableservice.posSelect()}`}
                                        />
                                        <Route
                                            component={BookingService}
                                            path={`${appRoutes.bookingservice.posSelect()}`}
                                        />
                                    </Switch>
                                </HoldingPageContainer>
                            </RouteWithRoles>
                        </Switch>
                    </PageContainer>
                )}
            </HoldingIdCheck>
        )}
    </User>
);

export default RouterComponent;
