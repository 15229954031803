import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Color } from 'csstype';
import { appTheme } from 'theme';

export enum InfoType {
    OK = 'OK',
    KO = 'KO',
    DEFAULT = 'DEFAULT',
    INFO = 'INFO',
    WARNING = 'WARNING',
    DISABLE = 'DISABLE',
    BLACK = 'BLACK',
}
export enum InfoSize {
    DEFAULT = 'DEFAULT',
    TALL = 'TALL',
    ROUNDED = 'ROUNDED',
}

export interface IProps {
    display?: InfoType;
    size?: InfoSize;
    large?: boolean;
    children: ReactElement | ReactElement[] | string | number | null;
    className?: string;
}

const palette: Record<InfoType, { color: Color; bgColor: Color }> = {
    OK: { color: appTheme.color.common.green, bgColor: appTheme.color.common.lightGreen },
    KO: { color: appTheme.color.common.darkRed, bgColor: appTheme.color.common.lightRed },
    DEFAULT: { color: appTheme.color.common.blue, bgColor: appTheme.color.common.lightBlue },
    INFO: { color: appTheme.color.grey[6], bgColor: appTheme.color.grey[0] },
    WARNING: { color: appTheme.color.common.orange, bgColor: appTheme.color.common.lightOrange },
    DISABLE: { color: appTheme.color.grey[6], bgColor: appTheme.color.grey[0] },
    BLACK: { color: appTheme.color.common.white, bgColor: appTheme.color.common.navy },
};

const dimension: Record<
    InfoSize,
    { height: number; fontSize: number; fontWeight: string | number; borderRadius: number }
> = {
    DEFAULT: {
        height: appTheme.dimension.height.small,
        fontSize: appTheme.typography.fontSizeXS,
        fontWeight: 'normal',
        borderRadius: appTheme.borderRadius.s,
    },
    TALL: {
        height: appTheme.dimension.height.element,
        fontSize: appTheme.typography.fontSizeS,
        fontWeight: appTheme.typography.fontWeight.bold,
        borderRadius: appTheme.borderRadius.s,
    },
    ROUNDED: {
        height: appTheme.dimension.height.inline,
        fontSize: appTheme.typography.fontSizeS,
        fontWeight: appTheme.typography.fontWeight.bold,
        borderRadius: appTheme.borderRadius.l,
    },
};

function Info({ display = InfoType.DEFAULT, large, size = InfoSize.DEFAULT, children, className }: IProps) {
    const { color, bgColor } = palette[display];
    const { height, fontSize, fontWeight, borderRadius } = dimension[size];
    return (
        <InfoTag
            color={color}
            bgColor={bgColor}
            large={!!large}
            className={className}
            height={height}
            fontSize={fontSize}
            fontWeight={fontWeight}
            borderRadius={borderRadius}
        >
            {children}
        </InfoTag>
    );
}

interface IInfoTagProps {
    color: Color;
    bgColor: Color;
    large: boolean;
    height: number;
    fontSize: number;
    fontWeight: number | string;
    borderRadius: number;
}

export const InfoTag = styled.span<IInfoTagProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: ${({ height }) => height}px;
    min-width: 60px;
    padding: 0 ${({ theme }) => theme.spacing.s}px;
    background-color: ${({ bgColor }) => bgColor};
    color: ${({ color }) => color};
    font-size: ${({ fontSize }) => fontSize}px;
    text-transform: capitalize;
    font-weight: ${({ fontWeight }) => fontWeight};
    border-radius: ${({ borderRadius }) => borderRadius}px;
`;

export default Info;
