import { Button, Struct, Title } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import GlobalCatalogTable from './GlobalCatalogTable';

const GlobalCatalogPage = ({ location, history }: RouteComponentProps) => {
    const { t } = useTranslation();
    const redirectToArticleCreatePage = () => {
        history.push(`${routes.admin.globalCatalog.article.add()}${location.search}`);
    };

    return (
        <Struct.Section>
            <Title grow mode="H2" value={t('page:admin.catalog.title') || ''} testID={'administration-global-catalog-title'}>
                <Button 
                  onClick={redirectToArticleCreatePage} 
                  testID="administration-global-catalog-add-article-btn"
                >
                  {t('app:button.add-article')
                }</Button>
            </Title>
            <Struct.Card>
                <GlobalCatalogTable />
            </Struct.Card>
        </Struct.Section>
    );
};

export default withRouter(GlobalCatalogPage);
