
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme, DefaultTheme } from 'styled-components';
import appRoutes from 'Router/routes';
import { OfferTemplateRouteParam, OfferTemplateType } from 'localTypes';
import LocalCatalog from 'feature/LocalCatalog';
import Container from 'feature/Container';
import PickUpPoints from 'feature/PickUpPoints';
import { Icon } from 'components';
import TradePage from 'feature/TradePage';
import RoutingClickAndCollectPage from './routingClickAndCollectPages';

interface IProps {
    theme: DefaultTheme;
}

const getRoutes = (t: any) => [
    {
        tabKey: 'offers',
        tabLabel: t('page:clickcollect.offers.tab'),
        path: appRoutes.cnc.offers.list,
        component: RoutingClickAndCollectPage,
        hideSelectLayoutPOS: true,
    },
    {
        tabKey: 'catalog',
        tabLabel: t('page:clickcollect.catalog.tab'),
        path: appRoutes.cnc.catalog.get,
        component: LocalCatalog,
        props: { offerType: OfferTemplateRouteParam.ClickCollect },
    },
    {
        tabKey: 'container',
        tabLabel: t('page:clickcollect.container.tab'),
        path: appRoutes.cnc.container.get,
        component: Container,
        props: { offerType: OfferTemplateRouteParam.ClickCollect },
        hideSelectLayoutPOS: true,
    },
    {
      tabKey: 'pickUpPoints',
      tabLabel: t('page:clickcollect.pickUpPoints.tab'),
      path: appRoutes.cnc.pickUpPoints.get,
      component: PickUpPoints,
      props: { offerType: OfferTemplateRouteParam.ClickCollect },
      hideSelectLayoutPOS: true,
  },
];

const ClickCollect = ({ theme }: IProps) => {
    const { t } = useTranslation();
    return (
        <TradePage
            routes={getRoutes(t)}
            pageTitle={t('app:app.cnc')}
            icon={<Icon.CnC color={theme.color.grey[6]}/>}
            offerTemplateType={OfferTemplateType.ClickCollect}
        />
    );
};

export default withTheme(ClickCollect);
