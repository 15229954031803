import { ErrorMessageLighter, InputLabel } from 'components';
import { FieldProps, FormikErrors } from 'formik';
import get from 'lodash/get';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BaseInputSecondary, InputState } from '../common';
import FieldContainer from './FieldContainer';
import { getHoursAndMinutesFromStringToISO, getTimeFromISO, getDisplayTime } from 'services/dateService';
import TimeField from 'react-simple-timefield';

enum Item {
    START = 'START',
    END = 'END',
}

interface IProps {
    readonly className?: string;
    readonly label?: string;
    readonly disabledDaysFields?: boolean;
    readonly disabledTimesField?: boolean;
    readonly labelStart?: string;
    readonly labelEnd: string;
    readonly timeLabelStart?: string;
    readonly timeLabelEnd: string;
    readonly width?: number;
    readonly timeWidth?: number;
    readonly validateFormOnChange?: boolean;
    readonly useDefaultErrorMessage?: boolean;
    readonly labelEndInline?: string;
    readonly timeLabelEndInline?: string;
    readonly labelsFontSize?: string;
    readonly handleInputChange?: Function;
}

const DaysInAdvanceInputField = (props: FieldProps & IProps) => {
    const {
        field,
        form,
        disabledDaysFields,
        disabledTimesField,
        labelStart,
        labelEnd,
        timeLabelStart,
        timeLabelEnd,
        width,
        timeWidth,
        validateFormOnChange,
        useDefaultErrorMessage = true,
        labelEndInline,
        timeLabelEndInline,
        labelsFontSize = 'L',
        handleInputChange,
    } = props;
    const [startTimeValue, setStartTimeValue] = useState(field.value?.orderStartDefaultDaily);
    const [endTimeValue, setEndTimeValue] = useState(field.value?.orderEndDefaultDaily);
    const [startValue, setStartValue] = useState(field.value?.min || 0);
    const [endValue, setEndValue] = useState(field.value?.max || 0);
    const nameStart = useMemo(() => `${field.name}.min`, [field.name]);
    const nameEnd = useMemo(() => `${field.name}.max`, [field.name]);
    const timeNameStart = useMemo(() => `${field.name}.orderStartDefaultDaily`, [field.name]);
    const timeNameEnd = useMemo(() => `${field.name}.orderEndDefaultDaily`, [field.name]);

    const handleChange = useCallback(
        (item: Item) =>
            ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
                if (item === Item.START) {
                    setStartValue(value);
                    if (!nameStart) {
                        return;
                    }
                    form.setFieldValue(nameStart, `${value}`.length > 0 ? Number(value) : null);
                    form.setFieldTouched(nameStart);
                }

                if (item === Item.END) {
                    setEndValue(value);
                    form.setFieldValue(nameEnd, `${value}`.length > 0 ? Number(value) : null);
                    form.setFieldTouched(nameEnd);
                }

                if (validateFormOnChange) {
                    setTimeout(() => {
                        form.validateForm();
                    }, 0);
                }
            },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [validateFormOnChange, nameStart, form, handleInputChange, nameEnd]
    );

    const handleTimeChange = (item: Item) => (_, time) => {
        if (!(item === Item.START ? timeNameStart : timeNameEnd)) {
            return;
        }

        form.setFieldValue(item === Item.START ? timeNameStart : timeNameEnd, getHoursAndMinutesFromStringToISO(time));
        form.setFieldTouched(item === Item.START ? timeNameStart : timeNameEnd);

        handleInputChange &&
            handleInputChange(
                item === Item.START ? 'orderStartDefaultDaily' : 'orderEndDefaultDaily',
                getHoursAndMinutesFromStringToISO(time)
            );

        if (validateFormOnChange) {
            setTimeout(() => {
                form.validateForm();
            }, 0);
        }
    };

    useEffect(() => {
        setStartValue(isNaN(field.value?.min) || field.value?.min === null ? '' : field.value.min);
        setEndValue(isNaN(field.value?.max) || field.value?.max === null ? '' : field.value.max);
        setStartTimeValue(field.value?.orderStartDefaultDaily === null ? '' : field.value.orderStartDefaultDaily);
        setEndTimeValue(field.value?.orderEndDefaultDaily === null ? '' : field.value.orderEndDefaultDaily);
    }, [field.value]);

    const renderError = (error?: string | FormikErrors<any>, inline?: boolean) => {
        if (!error) {
            return null;
        }
        // When is object, avoid render when all values are undefined
        if (typeof error === 'object' && Object.values(error).findIndex((e) => !!e) === -1) {
            return null;
        }

        return (
            <ErrorWrapper inline={inline}>
                <ErrorMessageLighter>{error}</ErrorMessageLighter>
            </ErrorWrapper>
        );
    };

    return (
        <FieldContainer {...props}>
            <Wrapper>
                <Vertical>
                    <InputWrapper>
                        <InputLabel
                            name={labelEnd}
                            htmlFor={nameEnd}
                            inline
                            noMarginRight
                            fontSize={labelsFontSize}
                            width={80}
                        />
                        <Input
                            {...field}
                            name={nameEnd}
                            width={width}
                            disabled={disabledDaysFields}
                            onChange={handleChange(Item.END)}
                            state={
                                !!get(form.errors, nameEnd) && !!get(form.touched, nameEnd)
                                    ? InputState.INVALID
                                    : InputState.VALID
                            }
                            value={endValue}
                            min="0"
                            step="1"
                        />
                        {labelEndInline && (
                            <InputLabel
                                name={labelEndInline}
                                htmlFor={nameEnd}
                                inline
                                noMarginRight
                                fontSize={labelsFontSize}
                            />
                        )}

                        {/* TIME */}
                        {timeLabelStart && (
                            <VerticalWithMargin marginLeft={!labelEndInline}>
                                <InputWrapper>
                                    <InputLabel
                                        name={timeLabelStart}
                                        htmlFor={timeNameStart}
                                        fontSize={labelsFontSize}
                                        inline
                                        noMarginRight
                                        width={175}
                                    />
                                    <TimeField
                                        value={startTimeValue ? getDisplayTime(getTimeFromISO(startTimeValue)) : ''}
                                        onChange={handleTimeChange(Item.START)}
                                        input={
                                            <Input
                                                {...field}
                                                name={timeNameStart}
                                                width={timeWidth}
                                                disabled={disabledTimesField}
                                                state={
                                                    !!get(form.errors, timeNameStart) &&
                                                    !!get(form.touched, timeNameStart)
                                                        ? InputState.INVALID
                                                        : InputState.VALID
                                                }
                                            />
                                        }
                                    />
                                </InputWrapper>
                            </VerticalWithMargin>
                        )}
                    </InputWrapper>

                    <br />

                    {labelStart && (
                        <VerticalWithMargin marginLeft={!labelEndInline}>
                            <InputWrapper>
                                <InputLabel
                                    name={labelStart}
                                    htmlFor={nameStart}
                                    fontSize={labelsFontSize}
                                    inline
                                    noMarginRight
                                    width={80}
                                />
                                <Input
                                    {...field}
                                    name={nameStart}
                                    width={width}
                                    disabled={disabledDaysFields}
                                    onChange={handleChange(Item.START)}
                                    state={
                                        !!get(form.errors, nameStart) && !!get(form.touched, nameStart)
                                            ? InputState.INVALID
                                            : InputState.VALID
                                    }
                                    value={startValue}
                                    min="0"
                                    step="1"
                                />

                                {/* TIME */}
                                {timeLabelEnd && (
                                    <InputWrapper>
                                        <InputLabel
                                            name={timeLabelEnd}
                                            htmlFor={timeNameEnd}
                                            inline
                                            noMarginRight
                                            fontSize={labelsFontSize}
                                            width={175}
                                        />
                                        <TimeField
                                            value={endTimeValue ? getDisplayTime(getTimeFromISO(endTimeValue)) : ''}
                                            onChange={handleTimeChange(Item.END)}
                                            input={
                                                <Input
                                                    {...field}
                                                    name={timeNameEnd}
                                                    width={timeWidth}
                                                    disabled={disabledTimesField}
                                                    state={
                                                        !!get(form.errors, timeNameEnd) &&
                                                        !!get(form.touched, timeNameEnd)
                                                            ? InputState.INVALID
                                                            : InputState.VALID
                                                    }
                                                />
                                            }
                                        />
                                        {timeLabelEndInline && (
                                            <InputLabel
                                                name={timeLabelEndInline}
                                                htmlFor={timeNameEnd}
                                                inline
                                                noMarginRight
                                                fontSize={labelsFontSize}
                                            />
                                        )}
                                    </InputWrapper>
                                )}
                            </InputWrapper>
                            {!useDefaultErrorMessage &&
                                (labelStart || labelEndInline || timeLabelStart || timeLabelEndInline) &&
                                renderError(
                                    {
                                        nameStart: get(form.errors, nameStart),
                                        nameEnd: get(form.errors, nameEnd),
                                        timeNameStart: get(form.errors, timeNameStart),
                                        timeNameEnd: get(form.errors, timeNameEnd),
                                    },
                                    true
                                )
                            }
                        </VerticalWithMargin>
                    )}
                </Vertical>
            </Wrapper>
        </FieldContainer>
    );
};

const Wrapper = styled.div<{ marginLeft?: number }>`
    display: flex;
    margin-left: ${({ marginLeft }) => marginLeft || '0'}px;
`;

const Vertical = styled.div`
    flex-direction: row;
`;

const VerticalWithMargin = styled(Vertical)<{ marginLeft?: boolean }>`
    &:not(:last-child) {
        margin-right: ${({ marginLeft }) => (marginLeft ? '38' : '0')}px;
    }
`;

const Input = styled(BaseInputSecondary)<Pick<IProps, 'width'>>`
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    text-align: center;
    font-size: ${({ theme }) => theme.typography.fontSizeL}px;
    margin: 0 ${({ theme }) => theme.spacing.xs}px;

    &::placeholder {
        color: ${({ theme }) => theme.color.grey[6]};
        font-weight: 200;
    }
`;

const InputWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const ErrorWrapper = styled.div<{ inline?: boolean }>`
    margin-left: ${({ theme, inline }) => (inline ? 0 : theme.spacing.xs)}px;
    margin-top: ${({ theme, inline }) => (inline ? theme.spacing.s : 0)}px;
    max-height: ${({ theme }) => theme.dimension.height.element}px;
`;

export default DaysInAdvanceInputField;
