import React, { useState } from 'react';
import { OfferItemToSell } from 'types/globalTypes';
import { ErrorToast } from 'components/ErrorToast';
import { toast } from 'react-toastify';
import MultipleToggle from '../../../../components/Inputs/MultipleToggle';


interface IOfferItemsToggleProps {
    id: string;
    toSell: OfferItemToSell;
    cashSystemCode: string | null;
    onToSellUpdate: (id: string, toSell: OfferItemToSell) => void;
}

const OfferItemsToggle = ({
    id,
    toSell,
    cashSystemCode,
    onToSellUpdate,
}: IOfferItemsToggleProps)  => {
    const [ toSellState, setToSellState ] = useState(toSell);
    const toSellList = [OfferItemToSell.DEACTIVATED, OfferItemToSell.TO_DECIDE, OfferItemToSell.ACTIVATED];
    const onChange = (value: any) => {
        if(value === OfferItemToSell.ACTIVATED && !cashSystemCode){
            toast(<ErrorToast errorMessage="NO_CASH_SYSTEM_CODE"/>);
        } else {
            setToSellState(value);
            if(toSellState !== value) {
                onToSellUpdate(id, value);
            }
        }
    };
    return (
        <MultipleToggle 
            values={toSellList}
            onChange={onChange} 
            initialValue={toSellState}
            width={50} 
            height={30}
            style={{
                selectedStyle: {
                    background: toSellState === OfferItemToSell.TO_DECIDE ? '#5b9ad5' : '#ffffff',
                    fontSize: '12px',
                    lineHeight: '32px',
                    textAlign: 'center',
                    transition: '250ms',
                },
                wrapperStyle: {
                    background: toSellState === OfferItemToSell.ACTIVATED ? '#70ad47' : '#d9d9d9',
                    padding: 0,
                },
            }}
        />
    );
}

export default OfferItemsToggle;
