import React from 'react';
import { TextInput, Button, Row, Column, Icon, QueryLoader } from 'components';
import styled from 'styled-components';
import {
    holdingPos_pos_Pos_plcs,
} from 'types/holdingPos';
import { ButtonType } from 'components/Button';
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import { appTheme } from "../../../theme";
import { QueryResult } from "../../../localTypes";
import { loader } from "graphql.macro";

const GET_PLCS = loader('../../../query/plcs.gql');

interface IProps {
    onChange: (plcs: holdingPos_pos_Pos_plcs[]) => void;
    plcs: holdingPos_pos_Pos_plcs[];
}

interface ICodePlcEditEProps {
    plcs: holdingPos_pos_Pos_plcs[];
    index: number;
    onChange: (plcs: holdingPos_pos_Pos_plcs[]) => void;
}

const customStyles = {
    clearIndicator: (prevStyle) => ({
        ...prevStyle,
        color: appTheme.color.common.red,
        ':hover': { color: appTheme.color.common.red },
        backgroundColor: appTheme.color.grey[0],
    }),
    multiValue: (base) => {
        return {
            ...base,
            backgroundColor: appTheme.color.grey[0],
        };
    },
    multiValueRemove: (base) => {
        return {
            ...base,
            backgroundColor: false,
            color: appTheme.color.common.blue,
            ':hover': { color: appTheme.color.common.blue },
            order: 1,
        };
    },
    multiValueLabel: (base) => {
        return {
            ...base,
            backgroundColor: false,
            order: 2,
        };
    },
    option: (base) => ({
        ...base,
        ':hover': { color: appTheme.color.common.blue, fontWeight: appTheme.typography.fontWeight.bold, backgroundColor : appTheme.color.grey[0] },
        backgroundColor: false,
    }),
    placeholder: (base) => ({
        ...base,
        color: appTheme.color.grey[5],
        fontSize: appTheme.typography.fontSizeS,
        fontWeight: appTheme.typography.fontWeight.bold,
    }),
    control: (base) => ({
        ...base,
        minHeight: '52px',
        width: '360px'
    }),
    indicatorSeparator: (styles) => ({display:'none'}),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        color : appTheme.color.common.navy
    })
};

const CodePlcEdit = ({ plcs, index, onChange }: ICodePlcEditEProps) => {
    const onRemove = (index: number) => {
        // @ts-ignore
        onChange([].concat(plcs.slice(0, index), plcs.slice(index + 1)));
    };

    return (
        <QueryLoader query={GET_PLCS}>
            {({ data }: QueryResult<{ plcs: holdingPos_pos_Pos_plcs[] }>) => {
                return <CodePlcWrapper>
                    <CodePlcInput value={plcs[index].id} onChange={id => onChange(plcs.map((plc, i) => {
                        if(index === i) return { ...plc, id, displayedServices: data.plcs.find((plc) => id === plc.id)?.services || [] } as holdingPos_pos_Pos_plcs;
                        return plc;
                    }))}
                    />
                    <Select
                        onChange={(values) => {
                            onChange(plcs.map((plc, i) => {
                                if(index === i) return { ...plc, displayedServices: values ? values.map(({ value, label }) => ({ id: value, wid: label })) : [] } as holdingPos_pos_Pos_plcs;
                                return plc;
                            }))
                        }}
                        isMulti
                        minMenuHeight={52}
                        options={plcs[index].services.map(({ id, wid }) => ({ value: id, label: wid }))}
                        value={plcs[index].displayedServices.map(({ id, wid }) => ({ value: id, label: wid }))}
                        closeMenuOnSelect={false}
                        styles={customStyles}
                    />
                    <Delete onClick={() => onRemove(index)}>
                        <Icon.Bin />
                    </Delete>
                </CodePlcWrapper>
            }}
        </QueryLoader>

    );
};



const PlcEdit = ({ plcs, onChange }: IProps) => {
    const [t] = useTranslation();
    const onAdd = (e: React.MouseEvent) => {
        onChange([
            ...plcs,
            { id: '', services: [], displayedServices: [] } as holdingPos_pos_Pos_plcs,
        ]);
    };
    return (
        <Wrapper>
            {plcs.map((plc, i) => (
                <CodePlcEdit key={String(i)} index={i} plcs={plcs} onChange={onChange} />
            ))}
            <AddButton
                type="button"
                display={ButtonType.GHOST}
                onClick={onAdd}
                inline
            >
                {t('schema:pos.addCodePlc')}
            </AddButton>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
`;


const AddButton = styled(Button)`
    margin: ${({ theme }) => theme.spacing.xs}px 0 ${({ theme }) => theme.spacing.xs}px 0;
    padding: ${({ theme }) => theme.spacing.xs}px 0 ${({ theme }) => theme.spacing.xs}px 0;
    border: 1px solid ${({ theme }) => theme.color.common.blue};
    border-radius: ${({ theme }) => theme.borderRadius.xs}px;
    width: 192px;
    height: 40px;
`;

const CodePlcWrapper = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    min-height: 52px;
    align-items: center;
    margin-bottom: 10px;
`;


const Delete = styled.a`
    padding: ${({ theme }) => theme.spacing.xs}px;
    cursor: pointer;
`;

const CodePlcInput = styled(TextInput)`
    padding-bottom: ${({ theme }) => theme.spacing.s - 12}px;
    width: 100px;
    margin-right: 13px;
`;
export default PlcEdit;
