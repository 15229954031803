import React, { ReactNode, useState, useEffect } from 'react';
import get from 'lodash/get';
import I18n from 'services/i18n';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Column, Row, Icon, Loader } from 'components';
import { getDates } from 'services/dateService';
import { offerPalette, OfferState, PlannedAndDisabledOffer, getOfferState } from 'services/offerService';

export enum DateButtonSize {
    default = 'default',
    minimal = 'minimal',
}

const DEFAULT_DATE_COUNT = 5;

interface IDateButtonProps {
    date: Date;
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
    available?: boolean;
    active: boolean;
    expired?: boolean;
    size?: DateButtonSize;
    availableColor?: string;
    notAvailableColor?: string;
    offerState?: OfferState;
    id?: string;
    blankCard?: boolean;
    testID?: string;
}

export function DateButton({
    date,
    onClick,
    className,
    disabled,
    active,
    expired,
    size = DateButtonSize.default,
    offerState,
    id,
    blankCard = false,
    testID
}: IDateButtonProps) {
    const SizedStyledButton = size === DateButtonSize.minimal ? StyledButtonMinimal : StyledButton;

    const tagColor = offerState ? offerPalette[offerState].highlighted : '';
    return (
        <SizedStyledButton {...(testID && {'data-test':`${testID}-item-${id}`})} id={id} {...{ disabled, onClick, className, active, blankCard }}>
            {!blankCard && (
                <>
                    <Column>
                        <WeekdayRow size={size}>{I18n.t(`app:weekDays.${date.getDay()}`)}</WeekdayRow>
                        <Row justify="center">
                            {date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric' })}
                        </Row>
                    </Column>
                    {!disabled && <StatusBall color={tagColor} expired={expired} />}
                </>
            )}
        </SizedStyledButton>
    );
}

interface IOfferDatesCarrouselProps {
    offers: PlannedAndDisabledOffer[];
    onSelectOffer: (offer) => void;
    activeOfferId?: string;
    dateButtonClassName?: string;
    dateCount?: number;
    firstDate?: Date;
    weekType?: string;
    loading?: boolean;
    renderHeader?: () => ReactNode;
    paginationButtons?: IDatesCarrouselPagination;
    useOnlyOpenClosedState?: boolean;
    isInAdvanceOfferTemplate?: boolean;
    testID?: string;
}

interface IDatesCarrouselPagination {
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
    handlePreviousButtonClick?: () => void;
    handleNextButtonClick?: () => void;
}

export default function OfferDatesCarousel({
    dateButtonClassName,
    onSelectOffer,
    dateCount = DEFAULT_DATE_COUNT,
    offers = [],
    paginationButtons = {
        hasNextPage: false,
        handlePreviousButtonClick: () => {},
        hasPreviousPage: false,
        handleNextButtonClick: () => {},
    },
    loading,
    renderHeader,
    activeOfferId,
    useOnlyOpenClosedState,
    isInAdvanceOfferTemplate = false,
    testID
}: IOfferDatesCarrouselProps) {
    const { t } = useTranslation();
    const { hasNextPage, hasPreviousPage, handlePreviousButtonClick, handleNextButtonClick } = paginationButtons;
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        if (!loading && isAnimating) {
            setIsAnimating(false);
        }
    }, [loading, isAnimating]);

    const dateButtonsRender = () => {
        const shownOfferDays = !!offers[dateCount] ? offers.splice(0, dateCount) : offers;
        const divStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

        return (
            <div style={{ ...(isAnimating ? animationBeforeStyle : animationAfterStyle), ...divStyle }}>
                {shownOfferDays.map((offer, index) => {
                    const [withdrawStart, withdrawEnd] = getDates(offer.withdrawRange);
                    const offerDateStart = new Date(withdrawStart);
                    const offerDateEnd = new Date(withdrawEnd);
                    const expired = offerDateEnd < new Date();

                    const orderRange = get(offer, 'orderRange', '');
                    const isDaysInAdvance = get(offer, 'isDaysInAdvance', false);
                    const isOfferAvailable = get(offer, 'published', false);
                    // there are some cases where we need the statusBall color to be the same as the states "open/closed"
                    // even when it is not the current offer state
                    // in those cases we pass "useOnlyOpenClosedState" as true and then we change the state according to isOfferAvailable
                    const offerState = useOnlyOpenClosedState
                        ? isOfferAvailable
                            ? OfferState.OPEN
                            : OfferState.CLOSED
                        : getOfferState({ orderRange, published: isOfferAvailable, isDaysInAdvance });

                    return (
                        <DateButton
                            testID={testID}
                            id={index?.toString?.()}
                            disabled={offer.disabled}
                            key={`${offer.id}-${index}`}
                            date={offerDateStart}
                            onClick={() => onSelectOffer(offer)}
                            className={dateButtonClassName}
                            active={activeOfferId === offer.id}
                            available={isOfferAvailable}
                            expired={expired}
                            offerState={offerState}
                            blankCard={!isInAdvanceOfferTemplate && offer.disabled}
                        />
                    );
                })}
            </div>
        );
    };

    const handleNavigationButtonsClick = (navigationFunc) => {
        setIsAnimating(true);
        navigationFunc();
    };

    const animationBeforeStyle = {
        opacity: 0,
    };

    const animationAfterStyle = {
        opacity: 1,
        transition: 'opacity 0.5s ease',
    };

    return (
        <Column>
            <Row>{!!renderHeader && renderHeader()}</Row>
            <OfferDateButtonsRow>
                <PlannedOffersChevronButtonWrapper>
                    {hasPreviousPage && (
                        <PlannedOffersChevronButton
                            onClick={() => handleNavigationButtonsClick(handlePreviousButtonClick)}
                        >
                            <Icon.ChevronLeft title={t('app:button.previous') || ''} />
                        </PlannedOffersChevronButton>
                    )}
                </PlannedOffersChevronButtonWrapper>
                {loading ? <Loader /> : dateButtonsRender()}
                <PlannedOffersChevronButtonWrapper>
                    {hasNextPage && (
                        <PlannedOffersChevronButton onClick={() => handleNavigationButtonsClick(handleNextButtonClick)}>
                            <Icon.ChevronRight title={t('app:button.next') || ''} />
                        </PlannedOffersChevronButton>
                    )}
                </PlannedOffersChevronButtonWrapper>
            </OfferDateButtonsRow>
        </Column>
    );
}

interface StyledButtonProps {
    active: boolean;
    blankCard: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    padding: 0 ${({ theme, active }) => (active ? theme.spacing.s - 1 : theme.spacing.s)}px;
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.grey[6]};
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    height: 51px;
    min-width: 90px;
    position: relative;
    background-color: transparent;
    &:not(:disabled) {
        ${({ theme, active }) =>
            active ? `border: 3px solid ${theme.color.common.blue};` : `border: 1px solid ${theme.color.grey[4]};`}
    }
    &:not(:disabled):before {
        content: '';
        position: absolute;
        height: 30%;
        width: 15px;
        right: -7px;
        top: -7px;
        background-size: 15px 15px;
        background-repeat: no-repeat;
        border-radius: 50%;
    }
    &:disabled {
        box-shadow: none;
        color: ${({ theme }) => theme.color.grey[4]};
        cursor: not-allowed;
        border: ${({ theme, blankCard }) => `1px solid ${blankCard ? theme.color.grey[2] : theme.color.grey[4]}`};
        background: ${({ theme, blankCard }) =>
            blankCard
                ? theme.color.grey[2]
                : `repeating-linear-gradient(-45deg, ${theme.color.common.white}, ${theme.color.common.white} 10%, ${theme.color.grey[4]} 11%)`};
    }
`;

const StyledButtonMinimal = styled.button<StyledButtonProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    padding: 0 ${({ theme, active }) => (active ? theme.spacing.s - 1 : theme.spacing.s)}px;
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    border-color: ${({ theme }) => theme.color.grey[4]};
    font-family: Rubik;
    color: ${({ theme }) => theme.color.grey[6]};
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.lighter};
    line-height: 14px;
    height: 30px;
    width: 60px;
    position: relative;
    background-color: transparent;
    &:not(:disabled) {
        ${({ theme, active }) =>
            active ? `border: 3px solid ${theme.color.common.blue};` : `border: 1px solid ${theme.color.grey[4]};`}
    }
    &:not(:disabled):before {
        content: '';
        position: absolute;
        height: 30%;
        width: 15px;
        right: -7px;
        top: -7px;
        background-size: 15px 15px;
        background-repeat: no-repeat;
        border-radius: 50%;
    }
    &:disabled {
        box-shadow: none;
        color: ${({ theme }) => theme.color.grey[4]};
        cursor: not-allowed;
        border: ${({ theme, blankCard }) => `1px solid ${blankCard ? theme.color.grey[2] : theme.color.grey[4]}`};
        background: ${({ theme, blankCard }) =>
            blankCard
                ? theme.color.grey[2]
                : `repeating-linear-gradient(-45deg, ${theme.color.common.white}, ${theme.color.common.white} 10%, ${theme.color.grey[4]} 11%)`};
    }
`;

const WeekdayRow = styled(Row)<{ size?: DateButtonSize }>`
    text-transform: capitalize;
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    justify-content: center;

    ${({ size }) =>
        size === DateButtonSize.minimal &&
        css`
            color: ${({ theme }) => theme.color.common.navy};
            font-size: ${({ theme }) => theme.typography.fontSizeXXS}px;
        `}
`;

const OfferDateButtonsRow = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 10%;
    margin-bottom: ${({ theme }) => theme.spacing.s}px;
`;

interface IStatusBallProps {
    color: string;
    expired?: boolean;
}

export const StatusBall = styled.span<IStatusBallProps>`
    height: 13px;
    width: 13px;
    background-color: ${({ color, expired }) => (expired ? offerPalette[OfferState.EXPIRED].highlighted : color)};
    border: 3px solid ${({ theme }) => theme.color.grey[0]};
    border-radius: 50%;
    position: absolute;
    right: -5px;
    top: -5px;
`;

const PlannedOffersChevronButton = styled.span`
    padding: ${({ theme }) => theme.spacing.xxs}px;
    cursor: pointer;
`;
const PlannedOffersChevronButtonWrapper = styled.span`
    width: 20px;
`;
