import React, { useState, useEffect, useMemo } from 'react';
import { loader } from 'graphql.macro';
import { Duration } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { BackButton, Button, Icon, Struct, Title, Loader } from 'components';
import OfferDatesCarousel from 'components/OfferDatesCarousel';
import { PaginationOptions } from 'localTypes';
import OfferPublishToggle from 'feature/OfferPublishToggle';
import OfferInfo from 'pages/ClickCollect/components/OfferInfo';
import { getDates, getDayFullName, getDisplayDate, getDisplayTime } from 'services/dateService';
import { getItem, LocalStorageKey, removeItem } from 'services/persistentData';
import { getPlannedAndUnavailableOffers } from 'services/offerService';
import { getOffer_offer_Offer } from 'types/getOffer';
import OfferItemTable from './OfferItemTable';
import OfferParameterFormPanel from './OfferParameterFormPanel';
import { OfferTemplateWithdrawalType } from '../../../../types/globalTypes';
import { appTheme } from "../../../../theme";
import { Arrow } from "../../../Communication/AddOrEditForm/components/ComponentHeader";
import { toast } from 'react-toastify';
import { ErrorToast } from '../../../../components/ErrorToast';
import { decodeEntityId, extractFilenameFromHeader } from '../../../../utils';
import OfferItemsCloner from './OfferItemsCloner';
import { getCarouselFirstDay, getDatesIntervalISO } from './utils';

const GET_OFFER_QUERY = loader('../query/getOffer.gql');
const GET_PLANNED_OFFERS_V2_QUERY = loader('../query/getPlannedOffersV2FromOfferTemplate.gql');
const CREATE_OFFER_FROM_TEMPLATE_MUTATION = loader('../../DailyOffersPage/query/createOfferFromTemplate.gql');
const DEFAULT_NUMB_OF_DAYS_DISPLAYED = 5;
const DAYS_TO_FETCH_WHEN_GOING_BACK = 6;

interface IProps {
    theme: DefaultTheme;
    match: {
        params: { idOffer: string; idHolding: string; idPos: string };
    };
}

export enum ReportsForPickupPoints {
    CUMULATIVE = 'CUMULATIVE',
    UNIT= 'UNIT',
    DELIVERY = 'DELIVERY'
}

export enum Report {
  CUMULATIVE = 'CUMULATIVE',
  UNIT= 'UNIT'
}

function OfferDetailsPage(props: IProps & RouteComponentProps) {
    const {
        theme,
        history,
        match: {
            params: { idOffer, idHolding, idPos },
        },
    } = props;

    const { t } = useTranslation();
    const [carouselFirstDay, setCarouselFirstDay] = useState(new Date());
    const [didChangeFirstDay, setDidChangeFirstDay] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [isCatalogPanelOpen, setCatalogPanelOpen] = useState(false);
    const [isOfferPanelOpen, setOfferPanelOpen] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState({ id: '' });
    const [shouldRefetchItems, setShouldRefetchItems] = useState(false);

    const defaultInterval = getDatesIntervalISO(carouselFirstDay);
    const [plannedOffersPagination, setPlannedOffersPagination] = useState<PaginationOptions>({
        first: DEFAULT_NUMB_OF_DAYS_DISPLAYED,
        limitDateISO: defaultInterval,
    });

    const [handleExcelReportButtonDebounce, setHandleExcelReportButtonDebounce] = useState<number | null>(null);

    const { loading, data } = useQuery(GET_OFFER_QUERY, {
        variables: { id: idOffer },
    });

    const currentOffer = data && (data.offer as getOffer_offer_Offer);
    const offerTemplate = currentOffer?.offerTemplate;
    const [withdrawStart, withdrawEnd] = currentOffer ? getDates(currentOffer?.withdrawRange) : ['', ''];
    const [orderStart, orderEnd] = currentOffer ? getDates(currentOffer?.orderRange) : ['', ''];
    const withdrawSlotDuration =
        currentOffer && currentOffer?.withdrawSlotDuration
            ? currentOffer?.withdrawSlotDuration
            : offerTemplate?.withdrawSlotDuration;

    const [createOfferFromTemplate] = useMutation(CREATE_OFFER_FROM_TEMPLATE_MUTATION);
    const {
        loading: plannedOffersLoading,
        data: plannedOffersData,
        refetch: plannedOffersRefetch,
    } = useQuery(GET_PLANNED_OFFERS_V2_QUERY, {
        variables: {
            idOfferTemplate: offerTemplate?.id,
            ...plannedOffersPagination,
        },
        fetchPolicy: 'network-only',
        skip: !offerTemplate,
    });

    const hasNextPage = plannedOffersData?.offerTemplate?.plannedOffers_v2?.pageInfo?.hasNextPage;
    const hasPreviousPage = plannedOffersData?.offerTemplate?.plannedOffers_v2?.pageInfo?.hasPreviousPage;
    const plannedOffers = plannedOffersData?.offerTemplate?.plannedOffers_v2?.edges?.map(({ node }) => node);
    const isDaysInAdvance = plannedOffersData?.offerTemplate?.daysInAdvanceEnd > 0;

    useEffect(() => {
        if (plannedOffers?.length > 0 && !didChangeFirstDay) {
            const firstDay = getCarouselFirstDay(plannedOffers);
            const carouselLimitToSet = new Date(firstDay);
            carouselLimitToSet.setUTCHours(0, 0, 0, 0);
            const newDateInterval = getDatesIntervalISO(firstDay);

            if (newDateInterval !== plannedOffersPagination.limitDateISO) {
                setPlannedOffersPagination({
                    ...plannedOffersPagination,
                    limitDateISO: newDateInterval,
                });
            }

            setCarouselFirstDay(firstDay);
            setDidChangeFirstDay(true);
        }
    }, [plannedOffers, didChangeFirstDay, plannedOffersPagination]);

    const plannedAndUnavailableOffers = useMemo(() => {
        const isFirstPage = !hasPreviousPage;
        const carouselDate = new Date();
        carouselDate.setDate(carouselFirstDay.getDate() + DEFAULT_NUMB_OF_DAYS_DISPLAYED * pageNumber);

        const today = new Date();
        today.setUTCHours(0, 0, 0, 0);

        const firstOfferDate =
            plannedOffers?.length > 0 ? new Date(getDates(plannedOffers?.[0]?.withdrawRange)[0]) : today;
        const firstVisibleDay = plannedOffers?.length > 0 ? carouselDate : today;

        const date = new Date();
        const lastDayDisplayedOnFirstRow = new Date(date.setDate(date.getDate() + 4));
        const firstDayNotOnFirstPage = firstOfferDate > lastDayDisplayedOnFirstRow;
        const fromDate = isFirstPage ? (firstDayNotOnFirstPage ? firstOfferDate : today) : firstVisibleDay;

        return plannedOffers ? getPlannedAndUnavailableOffers({ plannedOffers, fromDate, isDaysInAdvance }) : [];
    }, [plannedOffers, isDaysInAdvance, hasPreviousPage, carouselFirstDay, pageNumber]);

    useEffect(() => {
        if (!plannedOffersLoading && plannedOffers?.length > 0 && selectedOffer?.id !== idOffer) {
            const currentOffer = plannedOffers.find(({ id }) => id === idOffer);
            setSelectedOffer(currentOffer);
        }
    }, [plannedOffersLoading, plannedOffers, selectedOffer, idOffer]);

    /**
     * Use fetch api, because graphql is not good on dealing with file download.
     *
     * @param url
     * @param authToken
     */
    const fetchExcelReport = async (url: string, authToken: string) => {
        try {
            const headers = new Headers();
            headers.append('authorization', authToken);

            const report = await fetch(url, {
                headers,
            });
            if (report.status !== 200) {
                toast(<ErrorToast errorMessage="GENERATING_OFFER_REPORT" />);
                return;
            }

            const defaultReportFilename = t(`app:file.dailyOrdersExcelDefaultFilename`);
            const filename =
                extractFilenameFromHeader(report.headers.get('content-disposition')) || defaultReportFilename;

            await triggerBrowserFileDownload(await report.blob(), filename);
        } catch (error) {
            toast(<ErrorToast errorMessage="GENERATING_OFFER_REPORT" />);
        }
    };

    const handleExcelExport = async (reportType: Report) => {
        if (handleExcelReportButtonDebounce !== null) {
            // clear previous timer
            clearTimeout(handleExcelReportButtonDebounce);
            setHandleExcelReportButtonDebounce(null);
        }

        const url = composeExcelReportURL(window.config.API_GRAPHQL_ENDPOINT, idOffer, reportType);

        // trigger excel report generation half a second after the user stops triggering the event
        setHandleExcelReportButtonDebounce(
            setTimeout(async () => {
                await fetchExcelReport(url, getItem(LocalStorageKey.ACCESS_TOKEN) || '');
            }, 500)
        );
    };

    const composeExcelReportURL = (graphqlEndpoint: string, id: string, reportType: Report) => {
        const apiEndpoint = graphqlEndpoint.split('/graphql')[0];
        return `${apiEndpoint}/offer/${decodeEntityId(id)}/report?type=${reportType}`;
    };

    /**
     * Receives a blob (file like data) that will be used to create an anchor html tag associated to the file to
     * download and the respective filename.
     * After creating the anchor tag the file download will be triggered and the browser will display a
     * modal to save the file.
     *
     * @param blob
     * @param filename
     */
    const triggerBrowserFileDownload = async (blob: Blob, filename: string): Promise<void> => {
        const objectURL = URL.createObjectURL(blob);

        const anchor = document.createElement('a');
        anchor.href = objectURL;
        anchor.download = filename;

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        URL.revokeObjectURL(objectURL);
    };

    const toggleCatalogModal = () => {
        setCatalogPanelOpen(!isCatalogPanelOpen);
    };
    const toggleOfferModal = () => {
        setOfferPanelOpen(!isOfferPanelOpen);
    };

    const renderOfferParameters = ({
        orderStart,
        orderEnd,
        withdrawStart,
        withdrawEnd,
        withdrawSlotDuration,
        maxOrdersPerSlot,
        withdrawalType,
    }) => {
        return (
            <ParameterCard onClick={toggleOfferModal}>
                <Title
                    mode="H3"
                    value={t('page:clickcollect.daily-offers.title.parameters')}
                    grow
                    icon={<Icon.Cog color={theme.color.grey[6]} />}
                >
                    <Icon.SolidArrow />
                </Title>
                <Infos>
                    {withdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE ||
                    withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE
                        ? offerParametersSeatClickServeView({ orderStart, orderEnd }) :
                        withdrawalType === OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT ?
                            offerParametersInstantClickAndCollectView({ orderStart, orderEnd }) :
                            withdrawalType === OfferTemplateWithdrawalType.CLICK_AND_PICK_UP ?
                            offerParametersPickUpView({ orderStart, orderEnd }) :
                            offerParametersDetailsDefaultView({
                                orderStart,
                                orderEnd,
                                withdrawStart,
                                withdrawEnd,
                                withdrawSlotDuration,
                                maxOrdersPerSlot
                            })}
                </Infos>
            </ParameterCard>
        );
    };

    const offerParametersSeatClickServeView = ({ orderStart, orderEnd }) => {
        return (
            <OfferInfo name={t('page:clickcollect.daily-offers.label.orderRangeSeatClickAndServe')}>
                {`${getDisplayTime(orderStart)} - ${getDisplayTime(orderEnd)}`}
            </OfferInfo>
        );
    };

    const offerParametersInstantClickAndCollectView = ({ orderStart, orderEnd }) => {
        return <OfferInfo
            name={t('page:clickcollect.daily-offers.label.orderRange')}
        >
            {`${getDisplayTime(orderStart)} - ${getDisplayTime(orderEnd)}`}
        </OfferInfo>
    };

    const offerParametersPickUpView = ({ orderStart, orderEnd }) => {
        return (
            <OfferInfo name={t('page:clickcollect.daily-offers.label.orderRangeClickAndPickup')}>
                {`${getDisplayTime(orderStart)} - ${getDisplayTime(orderEnd)}`}
            </OfferInfo>
        );
    };

    const offerParametersDetailsDefaultView = ({
        orderStart,
        orderEnd,
        withdrawStart,
        withdrawEnd,
        withdrawSlotDuration,
        maxOrdersPerSlot,
    }) => {
        return (
            <>
                <OfferInfo name={t('page:clickcollect.daily-offers.label.orderRange')}>
                    {`${getDisplayTime(orderStart)} - ${getDisplayTime(orderEnd)}`}
                </OfferInfo>
                <OfferInfo name={t('page:clickcollect.daily-offers.label.withdrawRange')}>
                    {`${getDisplayTime(withdrawStart)} - ${getDisplayTime(withdrawEnd)}`}
                </OfferInfo>
                <OfferInfo name={t('page:clickcollect.daily-offers.label.withdrawSlotDuration')}>
                    {`${Duration.fromISO(withdrawSlotDuration).as('minutes')} ${t('app:duration.minutes')}`}
                </OfferInfo>
                <OfferInfo name={t('page:clickcollect.daily-offers.label.maxOrdersPerSlot')}>
                    {maxOrdersPerSlot || ''}
                </OfferInfo>
            </>
        );
    };

    const handlePlannedOffersPaginationClick = (click: '+' | '-') => {
        let newPlannedOffersPagination: PaginationOptions | undefined;
        let pageNumberTmp = pageNumber;

        if (click === '+') {
            pageNumberTmp = pageNumber + 1;
            newPlannedOffersPagination = {
                after: plannedOffersData?.offerTemplate?.plannedOffers_v2?.pageInfo?.endCursor,
                first: DEFAULT_NUMB_OF_DAYS_DISPLAYED,
            };
        } else if (click === '-') {
            pageNumberTmp = pageNumber > 0 ? pageNumber - 1 : pageNumber;

            // When we press the back arrow we send the 1st cursor (1st offer currently shown in the carousel) to FS
            // FS will then subtract the value passed in "last" from the position and do the request from that cursor
            // We are having an issue where if there was missing a day in between dates it would get the wrong cursor position
            // so it would always be fetching one day after the start of the week, to fix that we pass it as "6" (DAYS_TO_FETCH_WHEN_GOING_BACK)
            // to make sure it brings all the days included in the week time interval
            newPlannedOffersPagination = !plannedOffersData?.offerTemplate?.plannedOffers_v2?.pageInfo?.hasPreviousPage
                ? { first: DAYS_TO_FETCH_WHEN_GOING_BACK }
                : {
                      last: DAYS_TO_FETCH_WHEN_GOING_BACK,
                      before: plannedOffersData?.offerTemplate?.plannedOffers_v2?.pageInfo?.startCursor
                  };
        }
        setPageNumber(pageNumberTmp);

        if (newPlannedOffersPagination) {
            newPlannedOffersPagination = {
                ...newPlannedOffersPagination,
                limitDateISO: getDatesIntervalISO(carouselFirstDay, pageNumberTmp),
            };
        }

        newPlannedOffersPagination && setPlannedOffersPagination(newPlannedOffersPagination);
    };

    const onClickHandler = async (offer) => {
        if (offer.id) {
            setSelectedOffer(offer);
            history.push(`/${idHolding}/cnc/${idPos}/offers/daily-offers/${offer.id}`);
        } else if (!offer.disabled) {
            const forDate = getDates(offer?.withdrawRange)[0];
            const res: any = await createOfferFromTemplate({
                variables: { idOfferTemplate: offerTemplate.id, forDate },
            });
            if (res && res.data && res.data.createOfferFromTemplate) {
                await plannedOffersRefetch({
                    first: DEFAULT_NUMB_OF_DAYS_DISPLAYED,
                    idOfferTemplate: offerTemplate.id,
                });
                removeItem(LocalStorageKey.PLANNED_OFFERS_PAGINATION_STATE);
                history.push(`/${idHolding}/cnc/${idPos}/offers/daily-offers/${res.data.createOfferFromTemplate.id}`);
            }
        }
    };

    return loading ? (
        <Loader />
    ) : (
        <>
            <OfferParameterFormPanel offer={currentOffer} closeModal={toggleOfferModal} isOpen={isOfferPanelOpen} />
            <Struct.Section>
                <HeaderWrapper>
                    <Title
                        mode="H2"
                        value={offerTemplate.name}
                        subTitle={t('app:display-date', {
                            day: getDayFullName(withdrawStart),
                            date: getDisplayDate(withdrawStart),
                        })}
                        // @todo use router config
                        icon={
                            <Link to={`/${idHolding}/cnc/${idPos}/offers`}>
                                <BackButton />
                            </Link>
                        }
                    >
                        <OfferPublishToggle
                            offer={currentOffer}
                            isDaysInAdvance={currentOffer.offerTemplate?.daysInAdvanceEnd > 0}
                        />
                    </Title>
                </HeaderWrapper>
                <OfferDatesCarousel
                    loading={plannedOffersLoading}
                    activeOfferId={selectedOffer?.id}
                    onSelectOffer={onClickHandler}
                    offers={plannedAndUnavailableOffers}
                    paginationButtons={{
                        hasNextPage,
                        hasPreviousPage,
                        handleNextButtonClick: () => handlePlannedOffersPaginationClick('+'),
                        handlePreviousButtonClick: () => handlePlannedOffersPaginationClick('-'),
                    }}
                    isInAdvanceOfferTemplate={isDaysInAdvance}
                />
                {renderOfferParameters({
                    orderStart,
                    orderEnd,
                    withdrawStart,
                    withdrawEnd,
                    withdrawSlotDuration,
                    maxOrdersPerSlot: currentOffer.maxOrdersPerSlot,
                    withdrawalType: offerTemplate.withdrawalType,
                })}
                <Struct.Card>
                    <Title mode="H3" value={t('page:clickcollect.daily-offers.title.articles')} grow>
                        {offerTemplate?.daysInAdvanceEnd ? (
                            <OfferItemsCloner
                                idOfferTemplate={offerTemplate?.id}
                                idOffer={idOffer}
                                setShouldRefetchItems={setShouldRefetchItems}
                            />
                        ) : (
                            <></>
                        )}
                        <ExcelReportDropdown
                            buttonLabel={t('app:button.dailyOrdersExcelExport')}
                            onChange={async ({ value}) => {
                                await handleExcelExport(value as Report);
                            }}
                            options={[
                                ...Object.keys(
                                  offerTemplate.withdrawalType === OfferTemplateWithdrawalType.CLICK_AND_PICK_UP ? ReportsForPickupPoints : Report)
                                .map((e) => ({ value: e, label: t(`app:button.productsReport.${e}`) }))
                            ]}
                        />
                        <Button onClick={toggleCatalogModal}>{t('app:button.edit-articles')}</Button>
                    </Title>
                    <OfferItemTable
                        idOffer={idOffer}
                        toggleCatalogModal={toggleCatalogModal}
                        isCatalogPanelOpen={isCatalogPanelOpen}
                        setShouldRefetchItems={setShouldRefetchItems}
                        shouldRefetchItems={shouldRefetchItems}
                    />
                </Struct.Card>
            </Struct.Section>
        </>
    );
}

interface IExcelReportDropdown {
    buttonLabel: string;
    options: { value: string, label: string }[];
    onChange: (value: { value: string, label: string }) => void;
}

const ExcelReportDropdown = ({ buttonLabel, options, onChange }: IExcelReportDropdown) => {

    const [open, setOpen] = useState(false);

    return <ReportDropdownWrapper
        onBlur={() => setTimeout(() => {
            // detect when dropdown element loses focus
            // executing onBlur event only after a few milliseconds to allow the onClick on each Option to be detected and executed
            setOpen(false);
        }, 150)}
    >
        <ReportButton
            onClick={() => {
                setOpen(!open);
            }}
        >
            <ExcelIcon color={appTheme.color.common.blue}/>
            {buttonLabel}
            <ReportArrowWrapper>
                <Arrow turnUp={open} />
            </ReportArrowWrapper>
        </ReportButton>
        {open && <ReportOptionsWrapper>
            {options.map((op) => {
                return <ReportOption key={op.value} onClick={() => {
                    if (onChange) {
                        onChange(op);
                    }
                }}>
                    {op.label}
                </ReportOption>;
            })}
        </ReportOptionsWrapper>}
    </ReportDropdownWrapper>
};

const ReportButton = styled.button`
    height: ${({ theme }) => theme.dimension.height.element}px;
    width: 250px;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 ${({ theme }) => theme.spacing.s}px;
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    border: ${({ theme }) => `1px solid ${ theme.color.common.blue }`};
    background-color: ${({ theme }) => `${ theme.color.common.white }`};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.common.blue };
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    &:active {
        border-radius: ${({ theme }) => theme.borderRadius.s}px;
        border: ${({ theme }) => `1px solid ${ theme.color.common.blue }`};
    }
`;

const ReportDropdownWrapper = styled.div`
    position: relative;
`;

const ReportArrowWrapper = styled.div`
    margin-left: 5px
`;

const ReportOptionsWrapper = styled.div`
    position: absolute;
    z-index: 1;
    background-color: #FFFFFF;
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.boxShadow[0]};
`;

const ReportOption = styled.div`
    width: 100%;
    font-size: 16px;
    color: ${({ theme }) => theme.color.input.textColor};
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 10px;
    height: 40px;
    &:hover {
        background-color: ${({ theme }) => theme.color.input.backgroundColorSelected};
        color: ${({ theme }) => theme.color.common.white};
        border-radius: 4px;
    }
`;


const Infos = styled.div`
    display: flex;
    flex-wrap: wrap;
    & > * {
        margin-right: ${({ theme }) => theme.spacing.s}px;
    }
`;

// @ts-ignore
const ParameterCard = styled(Struct.Card)`
    margin-bottom: ${({ theme }) => theme.spacing.s}px;
    cursor: pointer;
`;

const HeaderWrapper = styled.div`
    width: 100%;
`;

export const ExcelIcon = styled(Icon.ExcelExport)`
    margin-right: 3px;
    margin-bottom: 7px;
`;

export default withRouter(withTheme(OfferDetailsPage));
