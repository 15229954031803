import { Color } from 'csstype';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

export enum CircleDisplay {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
}

interface IProps {
    color: Color;
    active: boolean;
    children: string | ReactNode;
    sizeCircle?: number | undefined;
    sizeText?: number | undefined;
}

export function Circle({ color, children, active, sizeCircle = 33, sizeText = 21 }: IProps) {
    const display = active ? CircleDisplay.ENABLED : CircleDisplay.DISABLED;
    return (
        <CircleSpan {...{ color, active, sizeCircle, display }}>
            <CircleText {...{ color, active, sizeText, display }}>{children}</CircleText>
        </CircleSpan>
    );
}

interface ICircleSpan {
    color: Color;
    active: boolean;
    sizeCircle: number;
    display?: CircleDisplay;
}

interface ICircleText {
    color: Color;
    active: boolean;
    sizeText: number;
    display?: CircleDisplay;
}

const CircleSpan = styled.div<ICircleSpan>`
    border: 0.1em solid ${({ color }) => color};
    border-radius: 100%;
    height: ${({ sizeCircle }) => sizeCircle}px;
    width: ${({ sizeCircle }) => sizeCircle}px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CircleText = styled.span<ICircleText>`
    font-size: ${({ sizeText }) => sizeText}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    color: ${({ color }) => color};
`;

export default Circle;
