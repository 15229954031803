import { QueryLoader, Select } from 'components';
import { loader } from 'graphql.macro';
import { QueryResult, OfferTemplateType } from 'localTypes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getNodes } from 'services/queryService';
import { setItem, getItem, LocalStorageKey } from 'services/persistentData';
import {
    getPOSWithOfferTemplate,
    getPOSWithOfferTemplate_get_Pos,
    getPOSWithOfferTemplate_list_edges_node_Pos,
} from 'types/getPOSWithOfferTemplate';
import { searchPOS, searchPOS_get_Pos, searchPOS_list_pointOfSales_pos_Pos } from 'types/searchPOS';

const SELECT_POS_QUERY = loader('../../query/getPOSWithOfferTemplate.gql');
const SELECT_POS = loader('../../feature/SelectPOS/searchPOS.gql');

interface IProps {
    offerTemplateType?: OfferTemplateType;
    match: {
        params: { idHolding?: string; idPos?: string };
    };
}
const SelectLayoutPOS = ({
    history,
    offerTemplateType,
    match: {
        params: { idHolding, idPos },
    },
}: IProps & RouteComponentProps) => {
    const { t } = useTranslation();

    let selectedPosId: string = idPos || '';

    function onChange(selected: { id: string; label: string }): void {
        const { pathname } = window.location;
        if (!idPos) {
            history.replace(`${pathname}/${selected.id}`);
        } else {
            history.replace(pathname.replace(idPos!, selected.id));

            const persistedIdPos = getItem(LocalStorageKey.POS_ID);
            if (selected.id !== persistedIdPos) {
                setItem(LocalStorageKey.POS_ID, selected.id);
            }
        }
    }

    return offerTemplateType === OfferTemplateType.BookingService ? (
        <QueryLoader
            displayErrorMessage={false}
            // @ts-ignore
            context={{
                debounceKey: 'pos',
            }}
            query={SELECT_POS}
            variables={{
                search: '%',
                idHolding,
                idPos: selectedPosId,
                useId: true,
            }}
        >
            {({ loading, data, errors, refetch }: QueryResult<searchPOS>) => {
                return (
                    <Select
                        testID={'select-POS-input-booking'}
                        onChange={onChange}
                        placeholder={t(`app:select-pos`) || ''}
                        loading={loading}
                        selected={data && (data.get as searchPOS_get_Pos)}
                        data={
                            idHolding && !loading && !errors && data
                                ? data.list.pointOfSales.map((d) => d.pos as searchPOS_list_pointOfSales_pos_Pos)
                                : []
                        }
                        onSearch={(search: any) => refetch({ search: `%${search}%`, idHolding })}
                    />
                );
            }}
        </QueryLoader>
    ) : (
        <QueryLoader
            displayErrorMessage={false}
            // @ts-ignore
            context={{
                debounceKey: 'pos',
            }}
            query={SELECT_POS_QUERY}
            variables={{
                search: '%',
                idHolding,
                idPos: selectedPosId,
                offerTemplateType: offerTemplateType,
                useId: !!selectedPosId,
            }}
        >
            {({ loading, data, errors, refetch }: QueryResult<getPOSWithOfferTemplate>) => {
                return (
                    <Select
                        testID={'select-POS-input-cc'}
                        onChange={onChange}
                        placeholder={t(`app:select-pos`) || ''}
                        loading={loading}
                        selected={data.get as getPOSWithOfferTemplate_get_Pos}
                        data={
                            !loading && !errors
                                ? (getNodes(data.list) as getPOSWithOfferTemplate_list_edges_node_Pos[])
                                : []
                        }
                        onSearch={(search) => refetch({ search: `%${search}%`, idHolding })}
                    />
                );
            }}
        </QueryLoader>
    );
};

export default withRouter(SelectLayoutPOS);
