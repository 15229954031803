import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { AddButton, DeleteButton, Panel, Tooltip, TableWithOptions , Info, InfoType, Icon } from 'components';
import { PanelContent } from 'components/Panel';
import { getArticleFamilyOptions } from 'services/articleService';
import {
    listLocalArticles,
    listLocalArticles_list_edges_node_LocalArticle,
    listLocalArticlesVariables,
} from 'types/listLocalArticles';
import { deleteByIdVariables } from 'types/deleteById';
import { createOfferItemVariables } from 'types/createOfferItem';
import { Omit } from 'localTypes';
import styled from 'styled-components';

export interface ILocalArticle extends Omit<listLocalArticles_list_edges_node_LocalArticle, 'offerItems'> {
    offerItemId?: string;
}

export type QueryParamKeys = 'search';

const LIST_LOCAL_ARTICLES = loader('../../query/listLocalArticles.gql');
const CREATE_OFFER_ITEM_MUTATION = loader('../../query/createOfferItem.gql');
const DELETE_BY_ID_MUTATION = loader('../../query/deleteById.gql');

const ICON_WIDTH = 20;

interface IProps {
    idOffer: string;
    match: {
        params: { idPos: string };
    };
    isOpen: boolean;
    closeModal: () => void;
}

function LocalArticle({
    item: { id, offerItemId, cashSystemCode, label, container},
    idOffer,
    queryVariables,
}: {
    item: ILocalArticle;
    idOffer: string;
    queryVariables: listLocalArticlesVariables;
}) {
    const { t } = useTranslation();
    return (
        <>
            <td style={{ 
                width: '100%', 
                maxWidth: '280px',
                overflow: 'hidden',
                overflowWrap: 'break-word'
            }}>{label}</td>
            <StyledTd>
                <StyledAddOrDeleteOfferButton>
                    {container !== null && (
                        <div style={{ marginRight: '20px' }}>
                            <Icon.Container width={ICON_WIDTH} height={ICON_WIDTH} />
                        </div>
                    )}
                    {!!offerItemId ? (
                        <>
                            <StyledInfo display={InfoType.OK}>
                                <>{t('page:clickcollect.daily-offers.articleOfferTag')}</>
                            </StyledInfo>
                            <DeleteOfferItem offerItemId={offerItemId} queryVariables={queryVariables} />
                        </>
                    ) : (
                        <AddLocalArticle
                            articleId={id}
                            idOffer={idOffer}
                            disabled={!cashSystemCode}
                            toolTipContent={t('page:clickcollect.daily-offers.noIdMenuSystem')}
                            queryVariables={queryVariables}
                        />
                    )}
                </StyledAddOrDeleteOfferButton>
            </StyledTd>
        </>
    );
}

function AddLocalArticle({
    articleId,
    idOffer,
    disabled,
    toolTipContent,
    queryVariables,
}: {
    articleId: string;
    idOffer: string;
    disabled?: boolean;
    toolTipContent: string;
    queryVariables: listLocalArticlesVariables;
}) {
    return (
        <Mutation
            update={(cache: any, { data: { createOfferItem } }: any) => {
                const cachedData = cache.readQuery({
                    query: LIST_LOCAL_ARTICLES,
                    variables: queryVariables,
                }) as listLocalArticles;

                const updatedEdges = cachedData.list.edges.map((edge) => {
                    const localArticle = edge.node as listLocalArticles_list_edges_node_LocalArticle;
                    if (localArticle.id === createOfferItem.localArticle.id) {
                        return {
                            ...edge,
                            node: {
                                ...edge.node,
                                offerItems: [{ id: createOfferItem.id, __typename: 'OfferItem' }],
                            },
                        };
                    }

                    return edge;
                });

                cache.writeQuery({
                    query: LIST_LOCAL_ARTICLES,
                    variables: queryVariables,
                    data: { ...cachedData, list: { ...cachedData.list, edges: updatedEdges } },
                });
            }}
            mutation={CREATE_OFFER_ITEM_MUTATION}
        >
            {(createOfferItem: (param: Record<'variables', createOfferItemVariables>) => Promise<any>) => {
                const onAddClick = () => {
                    createOfferItem({
                        variables: {
                            idOffer,
                            idLocalArticle: articleId,
                            quantityOverall: 0,
                            idsArticleTag: [],
                            idsArticleCertification: [],
                        },
                    });
                };
                return (
                    <StyledTooltip toolTipContent={disabled ? toolTipContent : ''}>
                        <AddButton disabled={disabled} onClick={onAddClick} />
                    </StyledTooltip>
                );
            }}
        </Mutation>
    );
}

function DeleteOfferItem({
    offerItemId,
    queryVariables,
}: {
    offerItemId: string;
    queryVariables: listLocalArticlesVariables;
}) {
    return (
        <Mutation
            update={(cache: any) => {
                const cachedData = cache.readQuery({
                    query: LIST_LOCAL_ARTICLES,
                    variables: queryVariables,
                }) as listLocalArticles;
                const updatedEdges = cachedData.list.edges.map((edge) => {
                    const localArticle = edge.node as listLocalArticles_list_edges_node_LocalArticle;
                    if (localArticle.offerItems[0] && localArticle.offerItems[0].id === offerItemId) {
                        return {
                            ...edge,
                            node: {
                                ...edge.node,
                                offerItems: [],
                            },
                        };
                    }
                    return edge;
                });
                cache.writeQuery({
                    query: LIST_LOCAL_ARTICLES,
                    variables: queryVariables,
                    data: { list: { ...cachedData.list, edges: updatedEdges } },
                });
            }}
            mutation={DELETE_BY_ID_MUTATION}
        >
            {(deleteById: (param: Record<'variables', deleteByIdVariables>) => Promise<any>) => {
                const onDeleteClick = () => {
                    deleteById({
                        variables: { id: offerItemId },
                    });
                };
                return <DeleteButton onClick={onDeleteClick} />;
            }}
        </Mutation>
    );
}

function LocalCatalogTablePanel({
    idOffer,
    isOpen,
    closeModal,
    match: {
        params: { idPos },
    },
}: IProps & RouteComponentProps) {
    const { t } = useTranslation();

    const defaultQueryVariables = {
        idOffer,
        withOfferItems: true,
        order: 'family,label',
        querySearch: [
            { key: 'idPos', value: idPos },
            { key: 'deleted', value: 'false' },
        ],
    };

    const renderLine = (queryVariables: listLocalArticlesVariables) => ({
        offerItems,
        ...rest
    }: listLocalArticles_list_edges_node_LocalArticle) => {
        const localArticle = {
            ...rest,
            offerItemId: offerItems && offerItems.length > 0 ? offerItems[0].id : undefined,
        };

        return (
            <tr key={rest.id}>
                <LocalArticle item={localArticle} idOffer={idOffer} queryVariables={queryVariables} />
            </tr>
        );
    };

    return (
        <Panel onClose={closeModal} open={isOpen} title={t('page:clickcollect.daily-offers.title.localCatalogPanel')}>
            <PanelContent>
                <TableWithOptions
                    withRouting={false}
                    withQueryVariables
                    query={LIST_LOCAL_ARTICLES}
                    variables={defaultQueryVariables}
                    searchPlaceholder={t('app:placeholder.search.article')}
                    renderLine={renderLine}
                    tags={{
                        'article.family': getArticleFamilyOptions(),
                    }}
                />
            </PanelContent>
        </Panel>
    );
}

const StyledAddOrDeleteOfferButton = styled.div`
    display: flex;
    align-items: center;
    padding-top: 12px;
`;

const StyledTd = styled.td`
    display: flex;
    justify-content: flex-end;
`;

const StyledInfo = styled(Info)`
    margin-right: ${({ theme }) => theme.spacing.s}px;
`;

const StyledTooltip = styled((props) => <Tooltip {...props} />)`
    top: ${({ theme }) => theme.spacing.xs}px;
    right: calc(100% - ${({ theme }) => theme.spacing.xs - 10}px);
    min-width: 180px;
    padding: 5px 5px 5px 10px;
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
`;
export default withRouter(LocalCatalogTablePanel);
