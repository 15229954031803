import { MultiStep } from 'components';
import React, { useState } from 'react';
import routes from 'Router/routes';
import { useTranslation } from 'react-i18next';
import { isDurationStartBeforeEnd } from 'services/form/offerValidator';
import {
    getOfferTemplate_offerTemplate_OfferTemplate as OfferTemplate,
    getOfferTemplate_offerTemplate_OfferTemplate_translations as Translations,
} from 'types/getOfferTemplate';
import {
    ArticleFamily,
    OfferTemplatePeriod,
    OfferTemplateSalesType,
    OfferTemplateWithdrawalType,
} from 'types/globalTypes';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import { OfferTemplateTranslation } from 'services/offerTemplateService';
import { serializeIQuantityRulesIntoFormValue } from 'services/mealHeartService';
import { FormikProps, FormikValues } from 'formik';
import { ButtonType } from 'components/Button';
import { isNull, isNaN, get, isEmpty } from 'lodash';
import { convertObjectToISO } from 'services/dateService';
import { Time } from 'components/Inputs/FormikFields/TimeSlotInputField';
import { DurationObject, Duration } from 'luxon';
import { IPaymentTypes } from 'types/createOfferTemplate';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
interface MaxMinData {
    min?: number;
    max: number;
}

export interface MealHeartQuantityRule {
    mealQuantity: MaxMinData;
    orderPerDay: MaxMinData;
    productsPerOrder: MaxMinData;
    sameProductPerOrder: MaxMinData;
}

export type formData = {
    holding: string;
    name: string;
    frName: string;
    enName: string;
    pos: string;
    description: string;
    frDescription: string;
    enDescription: string;
    frFullDescription: string;
    enFullDescription: string;
    fullDescription: string;
    period: OfferTemplatePeriod;
    salesType: OfferTemplateSalesType;
    withdrawalType: OfferTemplateWithdrawalType;
    orderStartDefaultDaily: string;
    orderEndDefaultDaily: string;
    withdrawStartDefault: string;
    withdrawEndDefault: string;
    startTime: string;
    endTime: string;
    hasChanged: boolean;
    withdrawSlotDuration: string | { duration: number; unit: string };
    minCancellationDelay: string | { duration: number; unit: string };
    minPreparationDelay: string;
    image: { id: string; hash: string; path: string; created: string };
    published: boolean;
    translations: OfferTemplateTranslation[];
    languages: string[];
    hasOnlineHelp: boolean;
    hasFullDescription: boolean;
    mealHeartRule: { selectedFamilies: ArticleFamily[]; allowedFamilies: string[] };
    mealHeartQuantityRule: MealHeartQuantityRule;
    loadedAdminRule: boolean;
    daysInAdvance: { min: number; max: number; orderStartDefaultDaily: string; orderEndDefaultDaily: string; };
    orderPerTimeSlot: { max: number };
    pickupPoints: {id:string, label: string}[];
    hasTranslations: boolean;
    enableComment?: boolean;
    // Payment methodes
    badge: boolean;
    cc: boolean;
    edenred: boolean;
    onsite: boolean;
    admission: string;
    subsidies: string;
    hasCat: boolean;
};
type errorData = Partial<
    Omit<Record<keyof formData, string>, 'mealHeartQuantityRule' | 'daysInAdvance' | 'withdrawSlotDuration'>
> &
    Record<'mealHeartQuantityRule', Record<keyof MealHeartQuantityRule, { min: string; max: string }>> &
    Record<
        'daysInAdvance',
        { min: string; max: string; orderStartDefaultDaily: string; orderEndDefaultDaily: string }
    > &
    Record<'withdrawSlotDuration', { duration: string; unit: string }>;

interface IAddOrEditForm {
    onSubmit: (values: formData) => void;
    offerTemplate: OfferTemplate | null;
    isModeEdit: boolean;
    idHolding: string;
    idPos: string;
}

const ORDER_START_DEFAULT_TIME = 'P0DT7H0M';
const ORDER_END_DEFAULT_TIME = 'P0DT14H30M';

const PICKUP_POINTS_QUERY = loader('../query/getOfferTemplatePickupPoints.gql');

export const OfferTemplatePeriodDic = () => {
    const { t } = useTranslation();
    return Object.values(OfferTemplatePeriod).map((id) => ({ id, label: t(`page:admin.cnc.${id}`) }));
};

export const OfferTemplateSalesTypeDic = () => {
    const { t } = useTranslation();
    return Object.values(OfferTemplateSalesType).map((id) => ({ id, label: t(`schema:OfferTemplateSalesType.${id}`) }));
};

export const OfferTemplateWithdrawalTypeDic = (shouldHideCAT: boolean) : { id: string, label: string }[]=> {
    const { t } = useTranslation();

    // @ts-ignore
    return Object.values(OfferTemplateWithdrawalType).reduce((acc, eachType) => {
        if (shouldHideCAT && eachType === OfferTemplateWithdrawalType.TABLE_SERVICE) {
            return acc;
        }

        return [
            ...acc,
            {
                id: eachType,
                label: t(`schema:OfferWithdrawalType.${eachType}`),
            },
        ];
    }, []);
};

const getOnlineContentWithoutTypename = (translations: Translations[] | null): OfferTemplateTranslation[] => {
    if (translations) {
        return translations.map((element) => {
            const { __typename, ...rest } = element;
            return { ...rest };
        });
    }
    return [];
};

const getOnlineContentLanguages = (translations: Translations[] | null): string[] => {
    if (translations) {
        return translations.map((element) => element.language);
    }
    return [];
};

const CreateOfferTemplate = ({ offerTemplate, onSubmit, isModeEdit, idHolding, idPos }: IAddOrEditForm) => {
    const { t } = useTranslation();

    const [salesType, setSalesType] = useState('');
    const [withdrawalType, setWithdrawalType] = useState('');
    const [adminLimitValues, setAdminLimitValues] = useState<Partial<MealHeartQuantityRule>>({});
    const [stepOneSchema, setStepOneSchema] = useState();
    const [stepTwoSchema, setStepTwoSchema] = useState();
    const [stepThreeSchema, setStepThreeSchema] = useState();

    const { data } = useQuery(PICKUP_POINTS_QUERY, {
        variables: { idHolding, idOfferTemplate: offerTemplate?.id },
        fetchPolicy: 'network-only',
        skip: withdrawalType !== OfferTemplateWithdrawalType.CLICK_AND_PICK_UP
    });

    const validateStepOne = (values: formData) => {
        values.name = values.translations[0].name;
        values.description = values.translations[0].description;
        values.frFullDescription =
            values.translations.length > 0 && values.translations[0].fullDescription
                ? values.translations[0].fullDescription
                : '';
    };

    const validateStepTwo = (values: formData) => {
        const errors = {} as errorData;
        const ISOwithdrawSlotDuration = convertObjectToISO(values.withdrawSlotDuration);
        const ISOWithdrawDurationRange = Duration.fromISO(values.withdrawEndDefault)
            .minus(Duration.fromISO(values.withdrawStartDefault))
            .toISO();
        const clickServeOrTableServiceOrInstantCCRule =
            values.withdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE ||
            values.withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE ||
            values.withdrawalType === OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT;

        const isClickAndPickup = values.withdrawalType === OfferTemplateWithdrawalType.CLICK_AND_PICK_UP;

        if (
            values.daysInAdvance.min === values.daysInAdvance.max &&
            !isDurationStartBeforeEnd(values.daysInAdvance.orderStartDefaultDaily, values.orderEndDefaultDaily)
        ) {
            errors.daysInAdvance = {
                min: '',
                max: '',
                orderStartDefaultDaily: t('page:admin.cnc.form.error.newRange'),
                orderEndDefaultDaily: t('page:admin.cnc.form.error.newRange')
            };
        }

        if (clickServeOrTableServiceOrInstantCCRule) {
            return errors;
        }

        if (Duration.fromISO(ISOwithdrawSlotDuration) >= Duration.fromISO(ISOWithdrawDurationRange)) {
            errors.withdrawSlotDuration = { duration: t('page:admin.cnc.form.error.invalidWidrawalWindow'), unit: '' };
        }

        if (values.daysInAdvance.min > values.daysInAdvance.max) {
            errors.daysInAdvance = {
                min: t('page:admin.cnc.timeSlotNotConform'),
                max: '',
                orderStartDefaultDaily: '',
                orderEndDefaultDaily: '',
            };
        }

        if (
            values.daysInAdvance.min === 0 &&
            values.daysInAdvance.min >= values.daysInAdvance.max &&
            !isDurationStartBeforeEnd(
                values.daysInAdvance.orderStartDefaultDaily,
                values.daysInAdvance.orderEndDefaultDaily
            )
        ) {
            errors.daysInAdvance = {
                min: '',
                max: '',
                orderStartDefaultDaily: t('page:admin.cnc.form.error.newRange'),
                orderEndDefaultDaily: '',
            };
            errors.daysInAdvance = {
                min: '',
                max: '',
                orderStartDefaultDaily: '',
                orderEndDefaultDaily: t('page:admin.cnc.form.error.newRange'),
            };
        }

        if (
            values.daysInAdvance.min === values.daysInAdvance.max &&
            !isDurationStartBeforeEnd(
                values.daysInAdvance.orderStartDefaultDaily,
                values.daysInAdvance.orderEndDefaultDaily
            )
        ) {
            errors.daysInAdvance = {
                min: '',
                max: '',
                orderStartDefaultDaily: '',
                orderEndDefaultDaily: t('page:admin.cnc.form.error.newRange'),
            };
        }

        if (
            !isClickAndPickup &&
            values.daysInAdvance.min === 0 &&
            !isDurationStartBeforeEnd(values.daysInAdvance.orderEndDefaultDaily, values.withdrawEndDefault)
        ) {
            errors.daysInAdvance = {
                min: '',
                max: '',
                orderStartDefaultDaily: '',
                orderEndDefaultDaily: `${t('page:admin.cnc.form.error.newRange')} ${t(
                    'page:admin.cnc.form.error.invalidEndRange'
                )}`,
            };
        }

        if (isNull(values.daysInAdvance.min) || isNaN(values.daysInAdvance.min)) {
            errors.daysInAdvance = {
                min: t('page:admin.cnc.required'),
                max: '',
                orderStartDefaultDaily: '',
                orderEndDefaultDaily: '',
            };
        }

        if (isNull(values.daysInAdvance.max) || isNaN(values.daysInAdvance.max)) {
            errors.daysInAdvance = {
                min: '',
                max: t('page:admin.cnc.required'),
                orderStartDefaultDaily: '',
                orderEndDefaultDaily: '',
            };
        }

        if (
            !isClickAndPickup &&
            !isDurationStartBeforeEnd(values.withdrawStartDefault, values.withdrawEndDefault)
        ) {
            errors.withdrawStartDefault = t('page:admin.cnc.form.error.newRange') || undefined;
            errors.withdrawEndDefault = t('page:admin.cnc.form.error.newRange') || undefined;
        }

        if (
            !clickServeOrTableServiceOrInstantCCRule &&
            !isClickAndPickup &&
            values.daysInAdvance.max === 0 &&
            !isDurationStartBeforeEnd(values.daysInAdvance.orderStartDefaultDaily, values.withdrawStartDefault)
        ) {
            errors.daysInAdvance = {
                min: '',
                max: '',
                orderStartDefaultDaily: `${t('page:admin.cnc.form.error.newRange')} ${t(
                    'page:admin.cnc.form.error.invalidStartRange'
                )}`,
                orderEndDefaultDaily: '',
            };
        }

        if (
            !clickServeOrTableServiceOrInstantCCRule &&
            !isClickAndPickup &&
            values.period === OfferTemplatePeriod.DAILY &&
            values.daysInAdvance.orderEndDefaultDaily &&
            values.withdrawEndDefault &&
            values.daysInAdvance.min === 0 &&
            (values.daysInAdvance.max !== values.daysInAdvance.min || values.daysInAdvance.max === 0) &&
            !isDurationStartBeforeEnd(values.daysInAdvance.orderEndDefaultDaily, values.withdrawEndDefault)
        ) {
            errors.withdrawEndDefault = t('page:admin.cnc.form.error.range') || undefined;
        }

        if (
            !clickServeOrTableServiceOrInstantCCRule &&
            !isClickAndPickup &&
            values.period === OfferTemplatePeriod.DAILY &&
            values.daysInAdvance.orderStartDefaultDaily &&
            values.withdrawStartDefault &&
            values.daysInAdvance.max === 0 &&
            !isDurationStartBeforeEnd(values.daysInAdvance.orderStartDefaultDaily, values.withdrawStartDefault)
        ) {
            errors.withdrawStartDefault = t('page:admin.cnc.form.error.range') || undefined;
        }

        if (
            !clickServeOrTableServiceOrInstantCCRule &&
            !isClickAndPickup &&
            values.daysInAdvance.min === 0 &&
            !isDurationStartBeforeEnd(values.daysInAdvance.orderEndDefaultDaily, values.withdrawEndDefault)
        ) {
            errors.daysInAdvance = {
                min: '',
                max: '',
                orderStartDefaultDaily: '',
                orderEndDefaultDaily: `${t('page:admin.cnc.form.error.newRange')} ${t(
                    'page:admin.cnc.form.error.invalidEndRange'
                )}`,
            };
        }
        
        return errors;
    };

    const validateStepThree = (values: formData) => {
        const errors = {} as errorData;
        if (
            !(
                adminLimitValues.mealQuantity?.min! <= values.mealHeartQuantityRule.mealQuantity?.min! &&
                values.mealHeartQuantityRule.mealQuantity?.min! <= values.mealHeartQuantityRule.mealQuantity?.max!
            )
        ) {
            errors.mealHeartQuantityRule = {
                ...errors.mealHeartQuantityRule,
                mealQuantity: {
                    ...errors.mealHeartQuantityRule?.mealQuantity,
                    min: t('page:admin.cnc.form.error.heartMealMinQuantity', {
                        min: Math.max(
                            adminLimitValues.mealQuantity?.min!,
                            values.mealHeartQuantityRule.mealQuantity?.min!
                        ),
                        max: Math.min(
                            adminLimitValues.mealQuantity?.max!,
                            values.mealHeartQuantityRule.mealQuantity?.max!
                        ),
                    }),
                },
            };
        }
        if (
            !(values.mealHeartQuantityRule.mealQuantity?.max! <= adminLimitValues.mealQuantity?.max!) ||
            !(values.mealHeartQuantityRule.mealQuantity?.max! <= values.mealHeartQuantityRule.productsPerOrder?.max!)
        ) {
            errors.mealHeartQuantityRule = {
                ...errors.mealHeartQuantityRule,
                mealQuantity: {
                    ...errors.mealHeartQuantityRule?.mealQuantity,
                    max: t('page:admin.cnc.form.error.heartMealMaxQuantity', {
                        min: values.mealHeartQuantityRule.mealQuantity?.min,
                        max:
                            values.mealHeartQuantityRule.productsPerOrder?.max! < adminLimitValues.mealQuantity?.max!
                                ? values.mealHeartQuantityRule.productsPerOrder?.max
                                : adminLimitValues.mealQuantity?.max,
                    }),
                },
            };
        }

        if (
            values.mealHeartQuantityRule.mealQuantity?.min! === 0 &&
            values.mealHeartQuantityRule.mealQuantity?.max! === 0
        ) {
            errors.mealHeartQuantityRule = {
                ...errors.mealHeartQuantityRule,
                mealQuantity: {
                    ...errors.mealHeartQuantityRule?.mealQuantity,
                    max: t('page:admin.cnc.form.error.range'),
                },
            };
        }

        if (
            !(
                values.mealHeartQuantityRule.orderPerDay?.max! <= adminLimitValues.orderPerDay?.max! &&
                values.mealHeartQuantityRule.orderPerDay?.max! >= adminLimitValues.orderPerDay?.min!
            )
        ) {
            errors.mealHeartQuantityRule = {
                ...errors.mealHeartQuantityRule,
                orderPerDay: {
                    ...errors.mealHeartQuantityRule?.orderPerDay,
                    max: t('page:admin.cnc.form.error.heartMealRange', {
                        min: adminLimitValues.orderPerDay?.min,
                        max: adminLimitValues.orderPerDay?.max,
                    }),
                },
            };
        }
        if (
            !(
                values.mealHeartQuantityRule.productsPerOrder?.max! <= adminLimitValues.productsPerOrder?.max! &&
                values.mealHeartQuantityRule.productsPerOrder?.max! >= adminLimitValues.productsPerOrder?.min!
            )
        ) {
            errors.mealHeartQuantityRule = {
                ...errors.mealHeartQuantityRule,
                productsPerOrder: {
                    ...errors.mealHeartQuantityRule?.productsPerOrder,
                    max: t('page:admin.cnc.form.error.heartMealRange', {
                        min: adminLimitValues.productsPerOrder?.min,
                        max: adminLimitValues.productsPerOrder?.max,
                    }),
                },
            };
        }
        if (
            !(
                values.mealHeartQuantityRule.sameProductPerOrder?.max! <= adminLimitValues.sameProductPerOrder?.max! &&
                values.mealHeartQuantityRule.sameProductPerOrder?.max! >= adminLimitValues.sameProductPerOrder?.min!
            ) ||
            !(
                values.mealHeartQuantityRule.sameProductPerOrder?.max! <=
                values.mealHeartQuantityRule.productsPerOrder?.max!
            )
        ) {
            errors.mealHeartQuantityRule = {
                ...errors.mealHeartQuantityRule,
                sameProductPerOrder: {
                    ...errors.mealHeartQuantityRule?.sameProductPerOrder,
                    max: t('page:admin.cnc.form.error.heartMealRange', {
                        min: adminLimitValues.sameProductPerOrder?.min,
                        max:
                            values.mealHeartQuantityRule.productsPerOrder?.max! <
                            adminLimitValues.sameProductPerOrder?.max!
                                ? values.mealHeartQuantityRule.productsPerOrder?.max
                                : adminLimitValues.sameProductPerOrder?.max,
                    }),
                },
            };
        }

        return errors;
    };

    const steps = [
        {
            name: isModeEdit ? t(`page:admin.cnc.steps.stepSub1`) : t(`page:admin.cnc.steps.step1`),
            component: (
                <StepOne
                    idHolding={idHolding}
                    idPos={idPos}
                    isModeEdit={isModeEdit}
                    validate={(values: formData) => validateStepOne(values)}
                    validationSchema={stepOneSchema}
                    setStepSchema={setStepOneSchema}
                    setSalesType={setSalesType}
                    setWithdrawalType={setWithdrawalType}
                />
            ),
            validationFunctionsPerButtons: {
                next: (props: FormikProps<FormikValues>) => {
                    return props.values.languages.length > 0;
                },
                draft: () => false,
            },
        },
        {
            name: t(`page:admin.cnc.steps.step2`),
            component: (
                <StepTwo
                    isModeEdit={isModeEdit}
                    setStepSchema={setStepTwoSchema}
                    validationSchema={stepTwoSchema}
                    validate={(values: formData) => validateStepTwo(values)}
                    pickupPoints={data?.pickUpPointsWithSelected}
                />
            ),
            validationFunctionsPerButtons: {
                next: (props: FormikProps<FormikValues>) => {
                    if (Object.keys(props.errors).length > 0) {
                        return false;
                    }

                    const hasPickUpPoints =
                        props.values.withdrawalType === OfferTemplateWithdrawalType.CLICK_AND_PICK_UP ? get(props.values, 'pickupPoints', []).length > 0 : true;

                    return (
                        props.values.daysInAdvance.orderStartDefaultDaily &&
                        props.values.daysInAdvance.orderEndDefaultDaily,
                        hasPickUpPoints
                    );
                },
                draft: () => false,
            },
        },
        {
            name: t(`page:admin.cnc.steps.step3`),
            component: (
                <StepThree
                    isModeEdit={isModeEdit}
                    validate={validateStepThree}
                    validationSchema={stepThreeSchema}
                    setStepSchema={setStepThreeSchema}
                    setAdminLimitValues={setAdminLimitValues}
                />
            ),
            validationFunctionsPerButtons: {
                draft: () => false,
                next: (props: FormikProps<FormikValues>) => {
                    if (!props.errors.mealHeartQuantityRule) {
                        return true;
                    }

                    for (const rule of Object.keys(props.errors.mealHeartQuantityRule)) {
                        if (
                            props.errors.mealHeartQuantityRule[rule] &&
                            (props.errors.mealHeartQuantityRule[rule].min ||
                                props.errors.mealHeartQuantityRule[rule].max)
                        ) {
                            return false;
                        }
                    }

                    return true;
                },
            },
        },
        {
            name: t(`page:admin.cnc.steps.step4`),
            component: (
                <StepFour
                    isModeEdit={isModeEdit}
                    setStepSchema={setStepTwoSchema}
                    validationSchema={stepTwoSchema}
                    validate={(values: formData) => validateStepTwo(values)}
                />
            ),
            validationFunctionsPerButtons: {
                next: (props: FormikProps<FormikValues>) => {
                    if (props.values.onsite) {
                        const { admission, subsidies } = props.values;
                        if (!isEmpty(admission) && !isEmpty(subsidies)) {
                            return true;
                        }
                    } else if (props.values.badge || props.values.cc || props.values.edenred) {
                        return true;
                    }
                    return false;
                },
                draft: () => false,
            },
        },
        {
            name: t(`page:admin.cnc.steps.step5`),
            component: <StepFive />,
        },
    ];

    const castedOfferTemplate = offerTemplate ? offerTemplate : {};

    const getTranslationByLanguage = (translations: Translations[], language: string) => {
        return translations.filter((translation) => {
            return translation.language === language;
        });
    };

    const valueFr =
        offerTemplate && offerTemplate.translations ? getTranslationByLanguage(offerTemplate.translations, 'fr') : [];
    const valueEn =
        offerTemplate && offerTemplate.translations ? getTranslationByLanguage(offerTemplate.translations, 'en') : [];
    const convertToDurationObject = (value) => {
        let durationISO: DurationObject = {};

        if (value.includes('M')) {
            // As long as the duration have 1 minute we will convert it all to minutes
            // Ex. 2 hours and 1 minute will appear as 121 minutes
            //
            // This is needed for the withdrawSlotDuration in Step2, we increased the max minutes to 720
            // and if we dont make this change then it would display the time incorrectly
            durationISO = {
                minutes: Duration.fromISO(value).as('minutes'),
            };
        } else {
            durationISO = Duration.fromISO(value).normalize().toObject();
        }
        const durationLabel: string = Object.keys(durationISO)[0].charAt(0) + Object.keys(durationISO)[0].slice(1);

        return {
            duration: durationISO ? durationISO.minutes || durationISO?.hours || durationISO?.days : 0,
            unit: durationLabel,
        };
    };

    const initialValues = {
        name: offerTemplate ? offerTemplate.name : '',
        frName: valueFr.length > 0 ? valueFr[0].name : '',
        enName: valueEn.length > 0 ? valueEn[0].name : '',
        description: offerTemplate ? offerTemplate.description : '',
        frDescription: valueFr.length > 0 ? valueFr[0].description : '',
        enDescription: valueEn.length > 0 ? valueEn[0].description : '',
        fullDescription: offerTemplate ? offerTemplate.fullDescription : '',
        frFullDescription: valueFr.length > 0 ? valueFr[0].fullDescription : '',
        enFullDescription: valueEn.length > 0 ? valueEn[0].fullDescription : '',
        published: offerTemplate ? offerTemplate.published : false,
        ...castedOfferTemplate,
        holding: idHolding,
        pos: offerTemplate ? offerTemplate.pos.id : '',
        posName: offerTemplate && offerTemplate.pos.name,
        orderPerTimeSlot: { max: offerTemplate ? offerTemplate.maxOrdersPerSlotDefault : 15 },
        daysInAdvance: {
            min: offerTemplate ? offerTemplate.daysInAdvanceStart : 0,
            max: offerTemplate ? offerTemplate.daysInAdvanceEnd : 0,
            orderStartDefaultDaily:
                offerTemplate && offerTemplate.orderStartDefault
                    ? offerTemplate.orderStartDefault
                    : ORDER_START_DEFAULT_TIME,
            orderEndDefaultDaily:
                offerTemplate && offerTemplate.orderEndDefault ? offerTemplate.orderEndDefault : ORDER_END_DEFAULT_TIME,
        },
        hasCat: offerTemplate && offerTemplate.pos.hasCat,
        maxOrdersPerSlotDefault: offerTemplate ? offerTemplate.maxOrdersPerSlotDefault : 1,
        period: offerTemplate ? offerTemplate.period : OfferTemplatePeriod.DAILY,
        salesType: offerTemplate ? offerTemplate.salesType : '',
        withdrawalType: offerTemplate && offerTemplate.withdrawalType ? offerTemplate.withdrawalType : '',
        minPreparationDelay:
            offerTemplate && offerTemplate.minPreparationDelay
                ? convertToDurationObject(offerTemplate.minPreparationDelay)
                : { duration: 5, unit: Time.MINUTES },
        minCancellationDelay:
            offerTemplate && offerTemplate.minCancellationDelay
                ? convertToDurationObject(offerTemplate.minCancellationDelay)
                : { duration: 5, unit: Time.MINUTES },
        withdrawSlotDuration:
            offerTemplate && offerTemplate.withdrawSlotDuration
                ? convertToDurationObject(offerTemplate.withdrawSlotDuration)
                : { duration: 5, unit: Time.MINUTES },
        image: { id: offerTemplate ? offerTemplate.image.id : '' },
        withdrawStartDefault:
            offerTemplate && offerTemplate.withdrawStartDefault ? offerTemplate.withdrawStartDefault : 'PT11H0M',
        withdrawEndDefault:
            offerTemplate && offerTemplate.withdrawEndDefault ? offerTemplate.withdrawEndDefault : 'PT15H0M',
        orderStartDefaultDaily:
            offerTemplate && offerTemplate.orderStartDefault ? offerTemplate.orderStartDefault : null,
        orderEndDefaultDaily:
            offerTemplate && offerTemplate.orderEndDefault
                ? offerTemplate.orderEndDefault
                : offerTemplate && offerTemplate.withdrawEndDefault,
        translations:
            offerTemplate && offerTemplate.translations && offerTemplate.translations.length > 0
                ? getOnlineContentWithoutTypename(offerTemplate.translations)
                : [
                      {
                          language: 'fr',
                          name: offerTemplate && offerTemplate.name ? offerTemplate.name : '',
                          description: offerTemplate && offerTemplate.description ? offerTemplate.description : '',
                          fullDescription:
                              offerTemplate && offerTemplate.fullDescription ? offerTemplate.fullDescription : '',
                          onlineHelp: null,
                      },
                  ],
        languages:
            offerTemplate && offerTemplate.translations && offerTemplate.translations.length > 0
                ? getOnlineContentLanguages(offerTemplate.translations)
                : ['fr'],
        hasFullDescription:
            !!(offerTemplate && offerTemplate.fullDescription && offerTemplate.fullDescription.length > 0) ||
            !!(
                (valueFr && valueFr.length > 0 && valueFr[0].fullDescription) ||
                (valueEn && valueEn.length > 0 && valueEn[0].fullDescription)
            ),
        hasTranslations: !!(
            (valueFr && valueFr.length > 0 && valueFr[0].onlineHelp) ||
            (valueEn && valueEn.length > 0 && valueEn[0].onlineHelp)
        ),
        mealHeartRule: offerTemplate ? offerTemplate.mealHeartRule : { selectedFamilies: [], allowedFamilies: [] },
        mealHeartQuantityRule: offerTemplate
            ? {
                  ...serializeIQuantityRulesIntoFormValue(offerTemplate.mealHeartRule.quantityRules),
              }
            : {},
        loadedAdminRule: false,
        edenred: offerTemplate ? offerTemplate.paymentMethod.paymentTypes.includes(IPaymentTypes.Edenred) : false,
        onsite: offerTemplate ? offerTemplate.paymentMethod.paymentTypes.includes(IPaymentTypes.OnSite) : false,
        pickupPoints: [],
        admission: offerTemplate ? offerTemplate?.admission : '',
        subsidies: offerTemplate ? offerTemplate?.subsidies : '',
    };

    const skipStepIndex = salesType === OfferTemplateSalesType.BUNDLE ? 2 : undefined;

    return (
        <MultiStep
            {...{ initialValues, steps }}
            onSubmit={onSubmit}
            backRoute={routes.cnc.offers.list(idHolding, idPos)}
            nextLabel={t('app:button.continue') || undefined}
            finishLabel={t(`page:admin.cnc.finishCreation`) || undefined}
            continueButtonStyle={ButtonType.CONFIRMATION}
            skipStepIndex={skipStepIndex}
            width={940}
        />
    );
};

export default CreateOfferTemplate;
