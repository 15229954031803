import React, { ReactElement, PureComponent } from 'react';
import styled from 'styled-components';
import { ITagOption } from 'components/Tag';

export interface IGroupBy {
    key: string;
    options: ITagOption[];
}

interface ITableProps<T> {
    headers?: ITableHeader[];
    headersWithoutPadding?: boolean;
    data: T[];
    renderLine: (item: T, index: number) => ReactElement;
    groupBy?: IGroupBy;
    testID?: string;
}

export interface ITableHeader {
    displayName: string | ReactElement;
    withoutPadding?: boolean;
    key: string;
}

function renderTBody<T>(data: T[], renderLine: (item: T, index: number) => ReactElement, groupBy?: IGroupBy) {
    /*
        The groupby function works with backend sorting.
        You need only add order field to the associated gql
    */
    if (!groupBy) return data.map(renderLine);
    let currentGroup: string | null;
    return data.map((item: any, key) => {
        const keys = groupBy.key.split('.');
        const newGroup = keys.reduce((result, key) => result[key], item);
        if (key === 0 || currentGroup !== newGroup) {
            currentGroup = newGroup;
            const groupInfo = groupBy.options.find(option => option.id === newGroup);
            return (
                <>
                    <tr key={key} style={{ height: 40 }}>
                        {/*colSpan is an arbitrary number to make sure that it covers the whole line*/}
                        <GroupTh scope="colgroup" id={newGroup} colSpan={100}>
                            {groupInfo && groupInfo.label}
                        </GroupTh>
                    </tr>
                    {renderLine(item, key)}
                </>
            );
        } else {
            return renderLine(item, key);
        }
    });
}

export default class Table<T> extends PureComponent<ITableProps<T>> {
    render() {
        const { headers, headersWithoutPadding, renderLine, groupBy, data, testID } = this.props;
        return (
            <MainTable hasHeader={!!headers} data-test={testID}>
                {!!headers && (
                    <thead>
                        <tr>
                            {headers.map((head, index) => (
                                <Th headersWithoutPadding={!!headersWithoutPadding || !!head.withoutPadding} key={`h${index}`}>{head.displayName}</Th>
                            ))}
                        </tr>
                    </thead>
                )}

                <tbody>{renderTBody(data, renderLine, groupBy)}</tbody>
            </MainTable>
        );
    }
}

const GroupTh = styled.th`
    height: 40px;
    padding-left: ${({ theme }) => theme.spacing.s}px;
    border-top: 1px solid ${props => props.theme.color.grey[2]};
    background-color: ${props => props.theme.color.grey[0]};
    text-align: left;
    font-weight: bold;
`;

const MainTable = styled.table<{ hasHeader: boolean }>`
    width: 100%;
    border-spacing: 0;
    color: ${({ theme }) => theme.color.grey[7]};
    & tr {
        height: ${({ theme }) => theme.dimension.height.tr}px;
    }
    tbody > tr > td {
        padding-left: ${({ theme }) => theme.spacing.s}px;
        border-top: 1px solid ${props => props.theme.color.grey[2]};
    }
    tbody > tr:first-child > td {
        border-top: ${({ hasHeader, theme }) => (hasHeader ? `1px solid ${theme.color.grey[1]}` : 'none')};
    }
`;

const Th = styled.th<{ headersWithoutPadding: boolean }>`
    padding-left: ${({ theme, headersWithoutPadding }) => headersWithoutPadding ? 0 : theme.spacing.s}px;
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    color: ${({ theme }) => theme.color.grey[4]};
    text-transform: uppercase;
    text-align: left;
    font-weight: normal;
    &:last-child {
        text-align: ${({ headersWithoutPadding }) => headersWithoutPadding ? 'right' : 'inherit'};
    }
`;
