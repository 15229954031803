import { DayPicker, OKButton } from 'components';
import React, { useState } from 'react';
import styled from 'styled-components';
import TimeInput from './Inputs/TimeInput';

interface IProps {
    value: string | undefined;
    label: string;
    initialValue: string;
    onChange: (duration: string) => void;
    onClose: () => void;
    className?: string;
}

function DurationPicker({ value, label, initialValue, onChange, onClose, className }: IProps) {
    const [durationInputDay, setDurationInputDay] = useState((value !== undefined ? value : initialValue).charAt(1));
    const [durationInputTime, setDurationInputTime] = useState((value !== undefined ? value : initialValue).substr(4));

    const handleChangeDay = (day: string) => {
        setDurationInputDay(day);
        onChange(`P${day}DT${durationInputTime}`);
    };
    const handleChangeTime = (time: string) => {
        setDurationInputTime(time);
        onChange(`P${durationInputDay}DT${time}`);
    };

    return (
        <Wrapper className={className}>
            <CustomDayPicker
                activeDays={[parseInt(initialValue.charAt(1))]}
                onChange={day => {
                    handleChangeDay(day);
                }}
            >
                <TimeInput
                    value={value}
                    onSelect={() => {}}
                    label={label}
                    initialValue={initialValue}
                    onChange={time => {
                        handleChangeTime(time);
                    }}
                />
                <RowWrapper>
                    <OKButton onClick={onClose} />
                </RowWrapper>
            </CustomDayPicker>
        </Wrapper>
    );
}

const CustomDayPicker = styled(DayPicker)`
    padding: 20px 10px 20px 5px;
`;

const Wrapper = styled.span`
    box-shadow: ${({ theme }) => theme.boxShadow[0]};
    position: absolute;
    margin-top: 40px;
    margin-left: 3px;
    width: 360px;
    z-index: 100;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;
export default DurationPicker;
