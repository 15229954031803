import React from 'react';
import { ErrorResponse } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { ErrorToast } from 'components/ErrorToast';
import { toast } from 'react-toastify';

const onError = ({ graphQLErrors }: { graphQLErrors?: any; }) => {
    if (!graphQLErrors || !graphQLErrors.length) {
        return;
    }

    graphQLErrors.forEach(
        (graphQLError: GraphQLError): void => {
            switch (graphQLError.message) {
                case 'AUTH_REQUIRED':
                case 'EXPIRED_AUTH':
                case 'INVALID_AUTH':
                    console.log('graphQLError', graphQLError.message);
                    window.location.href = '/login';
                    break;
                case 'NO_OFFER_ITEMS':
                    toast(<ErrorToast errorMessage="NO_OFFER_ITEMS" />);
                    break;
                case 'ALREADY_IN_USE':
                    toast(<ErrorToast errorMessage="ALREADY_IN_USE" />);
                    break;
                case 'DUPLICATED_CONTAINER':
                    toast(<ErrorToast errorMessage="DUPLICATED_CONTAINER" />);
                    break;
                default:
                    console.error('graphQLError', graphQLError);
                    toast(<ErrorToast errorMessage="ERROR" />);
                    break;
            }
        }
    );
};

const errorsMessages = (error: ErrorResponse): string[] => {
    if (!error.graphQLErrors || !error.graphQLErrors.length) {
        return [];
    }

    return error.graphQLErrors
        .map((graphQLError: GraphQLError): string =>
          // @ts-ignore
            (graphQLError?.extensions?.original?.message) || '')
        .filter(str => !!str);
};

const apolloErrors = { onError, errorsMessages };

export default apolloErrors;
