import styled from 'styled-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Bin } from 'components/Icons';
import { Icon, Tooltip } from 'components';
import { Button } from 'components';
import { ButtonType } from 'components/Button';
import { appTheme } from 'theme';

interface IComponentHeaderProps {
    rank: number;
    componentDescription: string;
    onDelete: (rank: number, e: React.MouseEvent<Element, MouseEvent>) => void;
    onUpComponent: (rank: number, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onDownComponent: (rank: number, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    totalComponents: number;
    hideRank?: boolean;
    disabled?: boolean;
}

//Renders a Header, that will display a Rank and title
export const ComponentHeader = ({
    rank,
    componentDescription,
    onDelete,
    onUpComponent,
    onDownComponent,
    totalComponents,
    hideRank = false,
    disabled = false,
}: IComponentHeaderProps) => {
    const { t } = useTranslation();

    const actualRank = rank + 1;
    return (
        <FlexRow justifyContent={'space-between'} marginBottom={hideRank ? 0 : 14} >
            {hideRank
                ? null
                : (
                    <FlexRow>
                        <Rank>{actualRank} </Rank>
                        <Title>{t(`page:communication.addMarketingCard.component.${componentDescription}`)}</Title>
                    </FlexRow>
                )}

            <FlexRow justifyContent={'space-evenly'} flexDirection={hideRank ? 'row-reverse' : 'row'}>
                <BinTooltip disabled={disabled} onDelete={e => onDelete(rank, e)} hideRank={hideRank} />

                <IconButton
                    onClick={e => {
                        onUpComponent(rank, e);
                    }}
                    disabled={disabled || rank === 0}
                >
                    <Arrow turnUp={true} />
                </IconButton>

                <IconButton
                    onClick={(e) => {
                        onDownComponent(rank, e);
                    }}
                    disabled={disabled || totalComponents === actualRank}
                >
                    <Arrow turnUp={false} />
                </IconButton>
            </FlexRow>
        </FlexRow>
    );
};

const BinTooltip = ({ onDelete, hideRank, disabled }) => {
    const [activeTooltip, setActiveTooltip] = useState(false);
    const { t } = useTranslation();

    return (
        <div onBlur={(e) => { setActiveTooltip(false) }}>
            <StyledTooltip
                clickedToolTip={activeTooltip}
                toolTipContent={
                    <>
                        <Header>
                            <div style={{ display: 'flex', fontSize: '12px' }}>
                                {t(`page:communication.addMarketingCard.component.tooltipHeader`)}
                            </div>
                            <Bin color={appTheme.color.common.black} height={14} width={13} />
                        </Header>

                        <FlexRow>
                            <AnnulerButton onClick={() => setActiveTooltip(false)}>
                                {t(`page:communication.addMarketingCard.component.tooltipCancel`)}
                            </AnnulerButton>

                            <StyledButton
                                display={ButtonType.ACTION}
                                onMouseDown={(e) => {
                                    onDelete(e);
                                    setActiveTooltip(false);
                                }}
                            >
                                {t(`page:communication.addMarketingCard.component.removeComponent`)}
                            </StyledButton>
                        </FlexRow>
                    </>
                }
            >
                <IconButton disabled={disabled} onClick={(e) => {
                    !hideRank ? setActiveTooltip(true) : onDelete(e);
                }}>
                    <Bin color={appTheme.color.common.red} height={14} width={13} />
                </IconButton>
            </StyledTooltip>
        </div>
    );
};

interface IArrow {
    turnUp: boolean;
}


interface IFlexRow {
    justifyContent?: string;
    marginBottom?: number;
    flexDirection?: string;
}


const FlexRow = styled.div<IFlexRow>`
    display: flex;
    flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
    justify-content: ${({ justifyContent }) => justifyContent ? 'space-between' : 'flex-start'};
    margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : 0}px;
`;

export const Arrow = styled(Icon.ArrowLeft) <IArrow>`
    height: 12px;
    width: 16px;
    transform: ${({ turnUp = false }) => `rotate(${turnUp ? '90deg' : '-90deg'})`};
`;

const Rank = styled.div`
    background-color: ${({ theme }) => theme.color.common.blue};
    color: ${({ theme }) => theme.color.common.white};
    font-size: ${({ theme }) => theme.typography.fontSizeM}px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    line-height: ${({ theme }) => theme.typography.size.S}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bolder};
    width: 25px;
    height: 23px;
    display: flex;
    border-radius: ${({ theme }) => theme.borderRadius.xs}px;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
`;

const Title = styled.div`
    font-size: ${({ theme }) => theme.typography.size.M}px;
    line-height: ${({ theme }) => theme.typography.lineHeight.M}px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.color.grey[6]};
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0;
`;

const IconButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: ${({ theme }) => theme.spacing.xs}px;
    border-radius: ${({ theme }) => theme.borderRadius.xs}px;
    width: 28px;
    height: 28px;
    background-color: ${({ theme }) => theme.color.grey[2]};
    &:disabled {
        opacity: 0.3;
    }
`;

const StyledTooltip = styled((props) => <Tooltip {...props} />)`
    top: 0px;
    right: 0px;
    width: 290px;
    padding: ${({ theme }) => theme.spacing.xs}px;
    z-index: 2;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
    width: 130px;
    min-width: 130px;
    height: 30px;
    cursor: pointer;
`;

const AnnulerButton = styled.span`
    font-size: ${({ theme }) => theme.typography.size.S}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bolder};
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    color: ${({ theme }) => theme.color.common.blue};
    font-family: Open Sans;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
    padding: 0 20px;
    display: flex;
    align-self: center;
    cursor: pointer;
`;