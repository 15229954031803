import React, { ChangeEvent, useState } from 'react';
import styled, { withTheme, DefaultTheme } from 'styled-components';

import { Loop, Close } from '../Icons';
import { BaseInput, IInputProps } from './common';

interface IProps extends IInputProps<string> {
    theme: DefaultTheme;
}

function SearchInput({ value, onChange, className, placeholder, theme, testID }: IProps) {
    const [inputValue, setInputValue] = useState(value);
    function onValueChange(event: ChangeEvent<HTMLInputElement>) {
        const { value } = event.currentTarget;
        setInputValue(value);
        onChange(value);
    }

    function reset() {
        setInputValue('');
        onChange('');
    }

    return (
        <Wrapper data-test={testID}>
            <LoopIcon color={theme.color.common.blue} height={theme.typography.fontSizeL} />
            <Input id='searchInput' placeholder={placeholder} className={className} value={inputValue} type="text" onChange={onValueChange} />
            {value && value!.length > 0 && (
                <Delete onClick={reset}>
                    <Close height={theme.typography.fontSizeL} color={theme.color.grey[4]} />
                </Delete>
            )}
        </Wrapper>
    );
}

interface InputProps {
    readonly width?: Number;
}

const Wrapper = styled.div`
    position: relative;
`;

const Input = styled(BaseInput)<InputProps>`
    width: 100%;
    padding-left: ${({ theme }) => theme.spacing.xs * 2 + theme.typography.fontSizeL}px;
    padding-rigth: ${({ theme }) => theme.spacing.xs * 2 + theme.typography.fontSizeL}px;
    text-align: left;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
`;

const LoopIcon = styled(Loop)`
    position: absolute;
    left: ${({ theme }) => theme.spacing.xs}px;
    top: ${({ theme }) => theme.spacing.xs}px;
`;

const Delete = styled.span`
    position: absolute;
    right: ${({ theme }) => theme.spacing.xs}px;
    top: ${({ theme }) => theme.spacing.xs}px;
    &:hover {
        path {
            fill: ${({ theme }) => theme.color.grey[6]};
        }
    }
`;

export default withTheme(SearchInput);
