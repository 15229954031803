import React, { ReactElement, useState } from 'react';
import PageWithTabs, { IRoute } from 'components/Layout/PageWithTabs';
import { RouteComponentProps, Redirect, Route, withRouter } from 'react-router-dom';
import PosIdCheck from 'Router/PosIdCheck';
import appRoutes from 'Router/routes';
import { OfferTemplateType } from 'localTypes';
import PageHeaderWithSelectPOS from 'components/Layout/PageHeaderWithSelectPOS';
import { GlobalLoader } from 'components/Loader';
import { OfferTemplateMapper } from 'services/offerService';

interface IProps {
    routes: IRoute[];
    offerTemplateType: OfferTemplateType;
    pageTitle: string;
    icon: ReactElement;
}
const PageContent = ({ routes, offerTemplateType, pageTitle, icon }: IProps) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const routeParam = OfferTemplateMapper[offerTemplateType];

    return (
        <PageWithTabs
            routes={routes}
            indexRoute={appRoutes[routeParam].index()}
            HeaderComponent={() => (
                <PageHeaderWithSelectPOS
                    title={pageTitle}
                    offerTemplateType={offerTemplateType}
                    icon={icon}
                    hideSelectLayoutPOS={routes[currentTabIndex]?.hideSelectLayoutPOS}
                />
            )}
            onChangeTab={setCurrentTabIndex}
        />
    );
};

const TradePage = ({
    match: {
        params: { idHolding },
    },
    icon,
    offerTemplateType,
    routes,
    pageTitle,
}: IProps & RouteComponentProps<{ idHolding?: string }>) => {
    const routeParam = OfferTemplateMapper[offerTemplateType];
    return (
        <PosIdCheck offerTemplateType={offerTemplateType}>
            {(loading, pos) => (
                <>
                    <Route
                        exact
                        path={appRoutes[routeParam].posSelect()}
                        render={(props) =>
                            pos ? (
                                <Redirect {...props} to={appRoutes[routeParam].index(idHolding, pos.id)} />
                            ) : (
                                <PageHeaderWithSelectPOS
                                    title={pageTitle}
                                    offerTemplateType={offerTemplateType}
                                    icon={icon}
                                />
                            )
                        }
                    />
                    <Route
                        path={`${appRoutes[routeParam].index()}/:tab?`}
                        render={(props) => {
                            const {
                                match: {
                                    params: { 
                                      // @ts-ignore
                                      idPos 
                                    },
                                },
                            } = props;
                            if (loading) return <GlobalLoader />;
                            return pos && pos.id === idPos ? (
                                <PageContent
                                    routes={routes}
                                    pageTitle={pageTitle}
                                    offerTemplateType={offerTemplateType}
                                    icon={icon}
                                />
                            ) : (
                                <Redirect {...props} to={appRoutes[routeParam].posSelect(idHolding)} />
                            );
                        }}
                    />
                </>
            )}
        </PosIdCheck>
    );
};

export default withRouter(TradePage);
