import { Select } from './Inputs';
import React from 'react';
import { changeLanguage, getSelectedLanguage, Languages } from '../services/i18n';
import { ISelectOption } from './Inputs/Select';
const IdiomSelector = () => {
    const languageSelected = getSelectedLanguage().valueOf();
    return (
        <Select
            id='idiom-selector'
            testID='idiom-selector-input'
            selected={{ id: languageSelected, label: languageSelected }}
            onChange={async (value: ISelectOption) => {
                await changeLanguage(value.id as Languages);
            }}
            renderItem={(item) => <strong>{item.id}</strong>}
            data={Object.values(Languages).map((lang) => ({ id: lang, label: lang }))}
            width={80}
        />
    );
};
export default IdiomSelector;
